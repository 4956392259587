const engTranslations = {
  translations: {
    loginPage: {
      emailAddress: 'Email Address',
      password: 'Password',
      login: 'Login',
    },
  },
};

export default engTranslations;
