/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import './SearchIcons.scss';

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';

import * as actions from '../../../actions';
import { ICONS_IN_PAGE } from '../../../lib/constants';
import DraggableIconFiltered from '../DraggableIconFiltered/DraggableIconFiltered';

const SearchIcons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [iconName, setIconName] = useState('');
  const [iconCategoriesOptions, setIconCategoriesOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [isFavorite, setIsFavorite] = useState(true);

  const localActions = {
    getIconCategories: (user, onError) => dispatch(actions.project.getIconCategories(user, onError)),
    getFilteredIcons: (user, name, category, favorite, page, onError) => dispatch(actions.project.getFilteredIcons(user, name, category, favorite, page, onError)),
  };

  const globalState = {
    iconCategories: useSelector((state) => state.project.iconCategories),
    filteredIcons: useSelector((state) => state.project.filteredIcons),
  };

  const errorCb = () => {
    removeCookie('currentUser', { path: '/login' });
  };

  useEffect(async () => {
    if (!cookies.currentUser) history.push('/login');
    await localActions.getIconCategories(cookies.currentUser, errorCb);
    await localActions.getFilteredIcons(cookies.currentUser, iconName, selectedOption, isFavorite, currentPage, errorCb);
  }, []);

  useEffect(() => {
    const categories = globalState.iconCategories.map((c) => ({
      ...c,
      label: c.Name,
    }));
    setIconCategoriesOptions(categories);
  }, [globalState.iconCategories]);

  const handlePageClick = (event) => {
    if (!cookies.currentUser) history.push('/login');
    localActions.getFilteredIcons(cookies.currentUser, iconName, selectedOption, isFavorite, event.selected, errorCb);
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    if (globalState.filteredIcons.iconsCount) {
      const numberOfPages = Math.ceil(globalState.filteredIcons.iconsCount / ICONS_IN_PAGE);
      setPageCount(numberOfPages);
    }
  }, [globalState.filteredIcons]);

  return (
    <div className="search-icons-container">
      <div className="filters">
        <label htmlFor="icon-name">
          Name:
          <input
            style={{ width: '100%', marginBottom: '5px' }}
            type="text"
            id="icon-name"
            value={iconName}
            onChange={(e) => setIconName(e.target.value)}
          />
        </label>
        {
          !!iconCategoriesOptions.length
          && (
            <label htmlFor="select-cat">
              Category:
              <Select
                id="select-cat"
                options={iconCategoriesOptions}
                onChange={(data) => {
                  setSelectedOption(data);
                }}
              />
            </label>
          )
        }
        <div className="search">
          <label htmlFor="favorite">
            Favorite:
            <input
              style={{ margin: '10px 10px' }}
              type="checkbox"
              id="favorite"
              checked={isFavorite}
              onClick={() => setIsFavorite(!isFavorite)}
            />
          </label>
          <button
            type="button"
            onClick={() => {
              if (!cookies.currentUser) history.push('/login');
              setCurrentPage(0);
              setPageCount(0);
              localActions.getFilteredIcons(cookies.currentUser, iconName, selectedOption, isFavorite, 0, errorCb);
            }}
          >
            Search

          </button>
        </div>
      </div>
      <div className="images-grid-filtered">
        {
          globalState.filteredIcons.icons.map((icon) => (
            <DraggableIconFiltered icon={icon} key={uuidv4()} />
          ))
        }
      </div>
      <div className="pagination">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={0}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>

    </div>
  );
};

export default SearchIcons;
