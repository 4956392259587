/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Dropdown,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import * as actions from '../../actions';
import { ReactComponent as PaintBrush } from '../../img/paint-brush2.svg';

// eslint-disable-next-line react/prop-types
const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <button
    style={{ border: 'none', backgroundColor: 'white', width: '30px' }}
    type="button"
    ref={ref}
    title="Set Brush Width"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <PaintBrush size={20} />
  </button>
));

// eslint-disable-next-line react/prop-types
const CustomWidthSVG = ({ size }) => (
  <svg height="20" width="60">
    <line x1="0" y1="0" x2="100%" y2="0" style={{ stroke: ' rgb(0, 0, 0)', strokeWidth: size }} />
  </svg>
);

const BrushSizeMenu = ({ onChange }) => {
  const dispatch = useDispatch();

  const localActions = {
    setBrushSize: (brushSize) => dispatch(actions.drawing.setBrushSize(brushSize)),
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Custom toggle
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onSelect={() => {
          if (onChange) onChange(2);
          else localActions.setBrushSize(2);
        }}
        >
          <CustomWidthSVG size={2} />
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => {
          if (onChange) onChange(4);
          else localActions.setBrushSize(4);
        }}
        >
          <CustomWidthSVG size={4} />
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => {
          if (onChange) onChange(6);
          else localActions.setBrushSize(6);
        }}
        >
          <CustomWidthSVG size={6} />
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => {
          if (onChange) onChange(8);
          else localActions.setBrushSize(8);
        }}
        >
          <CustomWidthSVG size={8} />
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => {
          if (onChange) onChange(10);
          else localActions.setBrushSize(10);
        }}
        >
          <CustomWidthSVG size={10} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BrushSizeMenu;
