/* eslint-disable max-len */
/* eslint-disable no-console */
import ProjectService from '../services/Project.service';
import * as types from './actionTypes';

export function setProjectTitle(projectTitle) {
  return async (dispatch) => {
    dispatch({ type: types.SET_PROJECT_TITLE, projectTitle });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function getProjects() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });

      const allProjects = await ProjectService.GetProjects();

      dispatch({ type: types.SET_ALL_PROJECTS, allProjects });
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}

export function saveProject() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });
      const state = await getState();
      const data = await JSON.stringify({
        projectLayers: state.layers.projectLayers,
        elementGroups: state.layers.elementGroups,
        canvasSize: state.layers.canvasSize,
      });
      const params = {
        name: state.project.projectTitle,
        description: 'Description',
        data,
      };
      const image = state.image.imageData;
      if (state.project.isNewProject) {
        const res = await ProjectService.SaveProject(params);
        dispatch({ type: types.SET_PROJECT_ID, projectId: res.id });
        if (image) {
          await ProjectService.UploadBackgroundImage(res.id, { image });
        }
        const allProjects = await ProjectService.GetProjects();
        dispatch({ type: types.SET_ALL_PROJECTS, allProjects });
      } else {
        const id = state.project.projectId;
        await ProjectService.UpdateProjectById(id, params);

        if (image) {
          await ProjectService.UploadBackgroundImage(id, { image });
        }
      }
      dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: false });
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}

export function deleteProject(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });

      const state = await getState();

      await ProjectService.DeleteProjectById(id);

      if (state.project.projectId === id) {
        dispatch({ type: 'RESET_REDUCER' });
      }

      const allProjects = await ProjectService.GetProjects();
      dispatch({ type: types.SET_ALL_PROJECTS, allProjects });

      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}

export function createNewProject() {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });

      dispatch({ type: 'RESET_REDUCER' });

      const allProjects = await ProjectService.GetProjects();

      dispatch({ type: types.SET_ALL_PROJECTS, allProjects });
      dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: false });

      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}

export function setCurrentProject(project) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });
      const proj = await ProjectService.GetProjectById(project.id);
      const projectData = await JSON.parse(proj.data);
      dispatch({ type: types.SET_ALL_LAYERS, projectLayers: projectData.projectLayers });
      dispatch({ type: types.SET_ELEMENT_GROUPS, elementGroups: projectData.elementGroups });
      dispatch({ type: types.SET_CURRENT_ELEMENT_GROUP, elementGroup: projectData.elementGroups[0] });
      dispatch({ type: types.SET_CANVAS_SIZE, canvasSize: projectData.canvasSize });
      dispatch({ type: types.SET_PROJECT_ID, projectId: project.id });
      dispatch({ type: types.SET_PROJECT_TITLE, projectTitle: proj.name });
      dispatch({ type: types.SET_IS_NEW_PROJECT, isNewProject: false });
      dispatch({ type: types.SET_IMAGE_URL, imageUrl: proj.backgroundImage || '' });
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
      dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}

export function setIsProjectChanged(isProjectChanged) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getIconCategories(user, errorCb) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });
      const iconCategories = await ProjectService.GetIconCategories(user);
      dispatch({ type: types.SET_ICON_CATEGORIES, iconCategories: iconCategories.Data });
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
      if (errorCb) errorCb();
    }
  };
}

export function getFilteredIcons(user, name, category, isFavorite, page, errorCb) {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_IS_LOADING, isLoading: true });
      const icons = await ProjectService.GetFilteredIcons(user, name, category, isFavorite, page);
      dispatch({
        type: types.SET_FILTERED_ICONS,
        filteredIcons: { icons: icons.Data, iconsCount: icons.TotalCount },
      });
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    } catch (error) {
      if (errorCb) errorCb();
      dispatch({ type: types.SET_IS_LOADING, isLoading: false });
    }
  };
}
