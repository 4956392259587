/* eslint-disable no-continue */
/* eslint-disable new-cap */
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { DEFAULT_SVG_ICON_SIZE } from './constants';

export const exportAsPdf = (elementId, projectTitle) => {
  const input = document.getElementById(elementId);
  const el = document.getElementById('canvas-container');
  const target = el.getBoundingClientRect();
  const targetInput = input.getBoundingClientRect();
  if (!input || !el || !target) return;
  html2canvas(input, {
    useCORS: true,
    backgroundColor: 'transparent',
    x: target.left - targetInput.left - 7,
    y: target.top - targetInput.top,
    width: target.width + 14,
    height: target.height + 14,
  })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
      });
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
      pdf.save(`${projectTitle}.pdf`);
    });
};

export const exportAsImage = (elementId, projectTitle, ext) => {
  const input = document.getElementById(elementId);
  const el = document.getElementById('canvas-container');
  const target = el.getBoundingClientRect();
  const targetInput = input.getBoundingClientRect();
  if (!input || !el || !target) return;
  html2canvas(input, {
    useCORS: true,
    backgroundColor: 'transparent',
    x: target.left - targetInput.left - 7,
    y: target.top - targetInput.top,
    width: target.width + 14,
    height: target.height + 14,
  })
    .then((canvas) => {
      // eslint-disable-next-line no-param-reassign
      canvas.style.display = 'none';
      const image = canvas.toDataURL(ext, 1.0);
      const a = document.createElement('a');
      a.setAttribute('download', `${projectTitle}.${ext}`);
      a.setAttribute('href', image);
      a.click();
    });
};

export const setLayerItemImage = (layerDivId, layerItemImageId) => {
  const input = document.getElementById(layerDivId);
  html2canvas(input, { backgroundColor: 'transparent' })
    .then((canvas) => {
      // eslint-disable-next-line no-param-reassign
      const itemImage = document.getElementById(layerItemImageId);
      if (itemImage) {
        itemImage.src = canvas.toDataURL();
      }
    });
};

export const getPointsForStraightLines = (
  x1, y1, x2, y2, distanceBetweenIcons, skippFirst = false,
) => {
  const lineSizeInPixels = Math.round(Math.hypot(
    x2 - x1,
    y2 - y1,
  ) * 100) / 100;
  const numberOfIconsToAdd = Math.floor(
    (lineSizeInPixels + DEFAULT_SVG_ICON_SIZE) / distanceBetweenIcons,
  );
  const points = [];
  if (Math.abs(x1 - x2) !== 0) {
    const xDistance = Math.round((Math.abs(x1 - x2) / numberOfIconsToAdd) * 100) / 100;

    const minX = Math.round(Math.min(x1, x2) * 100) / 100;
    const maxX = Math.round(Math.max(x1, x2) * 100) / 100;

    let currentX = minX;
    while (currentX <= maxX) {
      if (currentX === minX && skippFirst) {
        currentX = Math.round((xDistance + currentX) * 100) / 100;
        continue;
      } else {
        points.push({
          x: currentX,
          y: Math.round(((((y2 - y1) / (x2 - x1)) * (currentX - x1)) + y1) * 100) / 100,
        });
      }
      currentX = Math.round((xDistance + currentX) * 100) / 100;
    }
  } else {
    const yDistance = Math.abs(y1 - y2) / numberOfIconsToAdd;
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);

    for (let i = minY; i < maxY; i += yDistance) {
      if (i === minY && skippFirst) {
        continue;
      }
      points.push({
        x: (((i - y1) * (x2 - x1)) / (y2 - y1)) + x1,
        y: i,
      });
    }
  }
  return points;
};
