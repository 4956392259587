/* eslint-disable react/prop-types */
import React from 'react';
import { CompactPicker } from 'react-color';
import { useDispatch } from 'react-redux';

import * as actions from '../../actions';

const ColorPicker = ({ onChange }) => {
  const dispatch = useDispatch();

  const localActions = {
    setBrushColor: (color) => dispatch(actions.drawing.setBrushColor(color)),
  };

  return (
    <CompactPicker
      onChangeComplete={(color) => {
        if (onChange) onChange(color.hex);
        else localActions.setBrushColor(color.hex);
      }}
    />
  );
};

export default ColorPicker;
