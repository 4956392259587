/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';

const DeleteElementModal = () => {
  const dispatch = useDispatch();

  const localActions = {
    setOpenDeleteElementModal:
        (openDeleteElementModal) => dispatch(actions.layers.setOpenDeleteElementModal(openDeleteElementModal)),
    removeElementFromLayer: () => dispatch(actions.layers.removeElementFromLayer()),
    deleteIconsFromLine:
        () => dispatch(actions.drawing.deleteIconsFromLine()),
  };

  const globalState = {
    openDeleteElementModal: useSelector((state) => state.layers.openDeleteElementModal),
  };

  const closeModal = () => {
    localActions.setOpenDeleteElementModal(false);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={globalState.openDeleteElementModal}
      onHide={() => closeModal()}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure you want to delete element?
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button
          variant="success"
          onClick={async () => {
            await localActions.deleteIconsFromLine();
            localActions.removeElementFromLayer();
            closeModal();
          }}
        >
          Delete
        </Button>
        <Button variant="danger" onClick={() => closeModal()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteElementModal;
