import UserService from '../services/User.service';

export function getUserToken(username, password, onSuccess, onFail) {
  return async () => {
    try {
      const token = await UserService.GetUserToken(username, password);
      onSuccess(token);
    } catch (error) {
      onFail();
    }
  };
}
