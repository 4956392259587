/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './LayersTools.scss';

import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import {
  FaWindowMinimize,
} from 'react-icons/fa';
import {
  ImMoveDown,
  ImMoveUp,
} from 'react-icons/im';
import {
  IoIosAddCircle,
  IoIosRemoveCircle,
} from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import { FLOATING_TOOLBARS_OFFSET } from '../../../lib/constants';
import ElementGroup from './ElementGroup';

const LayersTools = () => {
  const [selectedElementGroupUuid, setSelectedElementGroupUuid] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const localActions = {
    setShowLayersToolbar:
        (showLayersToolbar) => dispatch(actions.layers.setShowLayersToolbar(showLayersToolbar)),
    setCurrentElementGroup:
        (elementGroup) => dispatch(actions.layers.setCurrentElementGroup(elementGroup)),
    addNewElementGroup:
        () => dispatch(actions.layers.addNewElementGroup()),
    removeElementGroup:
        (uuid) => dispatch(actions.layers.removeElementGroup(uuid)),
    setElementGroups:
        (elementGroups) => dispatch(actions.layers.setElementGroups(elementGroups)),
  };

  const globalState = {
    projectLayers: useSelector((state) => state.layers.projectLayers),
    elementGroups: useSelector((state) => state.layers.elementGroups),
    currentElementGroup: useSelector((state) => state.layers.currentElementGroup),
  };

  useEffect(() => {
    if (globalState.currentElementGroup && globalState.currentElementGroup.uuid) {
      setSelectedElementGroupUuid(globalState.currentElementGroup.uuid);
    }
  }, []);

  const removeElementGroup = () => {
    if (!selectedElementGroupUuid) {
      return;
    }
    localActions.removeElementGroup(selectedElementGroupUuid);
  };

  const addElementGroup = () => {
    localActions.addNewElementGroup();
  };

  const moveElementGroupUp = () => {
    if (selectedElementGroupUuid === null || !globalState.elementGroups.length
        || selectedElementGroupUuid === globalState.elementGroups[0].uuid) {
      return;
    }

    const elementIndex = globalState.elementGroups.findIndex((eg) => eg.uuid === selectedElementGroupUuid);
    const swappedElGroup = globalState.elementGroups[elementIndex - 1];
    const newElGroups = [...globalState.elementGroups];
    newElGroups[elementIndex - 1] = globalState.elementGroups[elementIndex];
    newElGroups[elementIndex] = swappedElGroup;
    localActions.setElementGroups(newElGroups);
  };

  const moveElementGroupDown = () => {
    if (selectedElementGroupUuid === null || !globalState.elementGroups.length
      || selectedElementGroupUuid === globalState.elementGroups[globalState.elementGroups.length - 1].uuid) {
      return;
    }

    const elementIndex = globalState.elementGroups.findIndex((eg) => eg.uuid === selectedElementGroupUuid);
    const swappedElGroup = globalState.elementGroups[elementIndex + 1];
    const newElGroups = [...globalState.elementGroups];
    newElGroups[elementIndex + 1] = globalState.elementGroups[elementIndex];
    newElGroups[elementIndex] = swappedElGroup;
    localActions.setElementGroups(newElGroups);
  };

  const setElementGroupVisability = (isShown, uuid) => {
    const newElGroups = globalState.elementGroups.map((eg) => {
      const newEg = { ...eg };
      if (newEg.uuid === uuid) newEg.isShown = isShown;
      return newEg;
    });
    localActions.setElementGroups(newElGroups);
  };

  const sortLayersList = (draggedIndex, droppedIndex) => {
    if (draggedIndex === droppedIndex) {
      return;
    }
    const newElementGroups = globalState.elementGroups
      .filter((layer, index) => index !== draggedIndex);
    newElementGroups.splice(droppedIndex, 0, globalState.elementGroups[draggedIndex]);
    localActions.setElementGroups(newElementGroups);
  };

  const onChangeTitle = (newName, uuid) => {
    const newElementGroups = globalState.elementGroups.map((eg) => {
      const newEg = { ...eg };
      if (eg.uuid === uuid) {
        newEg.name = newName;
      }
      return newEg;
    });
    localActions.setElementGroups(newElementGroups);
  };

  return (
    <Draggable
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: 0, y: 0 }}
      nodeRef={ref}
    >
      <div
        className="layers-tools-container"
        ref={ref}
        style={{
          zIndex: (globalState.projectLayers.length * 2) + FLOATING_TOOLBARS_OFFSET,
        }}
      >
        <strong className="layers-tools-header">
          <div className="title">Layers</div>
          <FaWindowMinimize
            size={15}
            onClick={() => localActions.setShowLayersToolbar(false)}
          />
        </strong>
        <div
          className="layers-tools-content-container"
        >
          {
            globalState.elementGroups.map((elementGroup, index) => (
              <ElementGroup
                key={elementGroup.uuid}
                elementGroup={elementGroup}
                index={index}
                isSelected={elementGroup.uuid === selectedElementGroupUuid}
                onSelect={() => {
                  setSelectedElementGroupUuid(elementGroup.uuid);
                  localActions.setCurrentElementGroup(elementGroup);
                }}
                sortLayersList={sortLayersList}
                onChangeTitle={(newTitle, uuid) => onChangeTitle(newTitle, uuid)}
                setElementGroupVisability={
                  (isShown, uuid) => setElementGroupVisability(isShown, uuid)
                }
              />
            ))
          }
        </div>
        <div className="layers-tools-footer">
          <IoIosAddCircle
            size={30}
            title="Add Layer"
            fill="green"
            onClick={() => addElementGroup()}
          />
          <IoIosRemoveCircle
            size={30}
            title="Remove Layer"
            fill="red"
            onClick={() => removeElementGroup()}
          />
          <ImMoveUp
            size={30}
            title="Move Layer Up"
            fill="#00A9E2"
            onClick={() => moveElementGroupUp()}
          />
          <ImMoveDown
            size={30}
            title="Move Layer Down"
            fill="#00A9E2"
            onClick={() => moveElementGroupDown()}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default LayersTools;
