/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './SelectedObjectsTools.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import {
  FaWindowMinimize,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';

// import { v4 as uuidv4 } from 'uuid';
import * as actions from '../../../actions';
import {
  DEFAULT_SVG_ICON_SIZE, DRAGGABLE_ICONS, DRAW_STYLE, FLOATING_TOOLBARS_OFFSET,
} from '../../../lib/constants';
import BrushSizeMenu from '../../../shared/BrushSizeMenu/BrushSizeMenu';
import ColorPicker from '../../../shared/ColorPicker/ColorPicker';

const icons = [...DRAGGABLE_ICONS.trafficCones, ...DRAGGABLE_ICONS.trafficSignes];

let options = icons.map((Icon, index) => ({
  value: index,
  icon: Icon,
  label: `${index < 4 ? 'Traffic Cone' : 'Traffic Sign'} ${index}`,
  index,
}));

const { Option } = components;
const IconOption = (props) => {
  const Icon = props.data.icon;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Option {...props}>
      <Icon size={40} fill="black" />
      <span style={{ color: 'black' }}>{props.data.label}</span>
    </Option>
  );
};

const SelectedObjectTools = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedElementType, setSelectedElementType] = useState(null);
  const [toolbarTitle, setToolbarTitle] = useState('Selected Object');
  const [addIconsToLine, setAddIconsToLine] = useState(false);
  const [distanceBetweenIcons, setDistanceBetweenIcons] = useState(100);
  const [iconSize, setIconSize] = useState(DEFAULT_SVG_ICON_SIZE);
  const [iconAngle, setIconAngle] = useState(null);
  const [currentIcon, setCurrentIcon] = useState(options[0]);
  const [textWidth, setTextWidth] = useState(200);
  const [textHeight, setTextHeight] = useState(50);
  const [openColorPicker, setOpenColorPicler] = useState(false);

  const localActions = {
    setShowSelectedObjectToolbar:
      (showSelectedObjectTools) => dispatch(actions.drawing.setShowSelectedObjectToolbar(showSelectedObjectTools)),
    addIconsToLine:
      (Icon, element, lineType, layerUuid, distance, size) => dispatch(actions.drawing.addIconsToLine(Icon, element, lineType, layerUuid, distance, size)),
    deleteIconsFromLine:
      () => dispatch(actions.drawing.deleteIconsFromLine()),
    setDashedLine:
      () => dispatch(actions.drawing.setDashedLine()),
    checkIfLineHasIcons:
      () => dispatch(actions.drawing.checkIfLineHasIcons()),
    setUpdateSelectedElement:
      (update) => dispatch(actions.layers.setUpdateSelectedElement(update)),
    changeElementProperty:
      (selectedEl, property, value) => dispatch(actions.layers.changeElementProperty(selectedEl, property, value)),
    changeIconSize:
      (size) => dispatch(actions.layers.changeIconSize(size)),
    updateText:
      (text) => dispatch(actions.layers.updateText(text)),
    changeIconRotation:
        (angle, iconUuid) => dispatch(actions.layers.changeIconRotation(angle, iconUuid)),
  };

  const globalState = {
    projectLayers: useSelector((state) => state.layers.projectLayers),
    selectedElement: useSelector((state) => state.layers.selectedElement),
    updateSelectedElement: useSelector((state) => state.layers.updateSelectedElement),
    brushColor: useSelector((state) => state.drawing.brushColor),
    filteredIcons: useSelector((state) => state.project.filteredIcons),
  };

  const onSpaceBetweenIconsChange = async (value) => {
    if (!currentIcon || !selectedElement || !selectedElementType || !globalState.selectedElement.layerUuid) return;
    await localActions.deleteIconsFromLine();
    localActions.addIconsToLine(
      currentIcon.signImageURL
        ? () => (
          <img src={currentIcon.signImageURL} alt="icon" style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
        ) : currentIcon.icon,
      selectedElement,
      selectedElementType,
      globalState.selectedElement.layerUuid,
      Number(value),
      iconSize,
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openColorPicker && ref.current && !ref.current.contains(e.target)) {
        setOpenColorPicler(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openColorPicker]);

  const onIconSizeChanged = (size) => {
    setIconSize(size);
    if (size) { localActions.changeIconSize(size); }
  };

  const onIconAngleChanged = async (angle) => {
    setIconAngle(Number(angle));
    await localActions.changeIconRotation(Number(angle), globalState.selectedElement.elementUuid);
  };

  const getToolbarTitle = (type) => {
    switch (type) {
      case DRAW_STYLE.FREEFORM:
        return 'Freeform';
      case DRAW_STYLE.POLYLINES:
        return 'Polyline';
      case DRAW_STYLE.STRAIGHT_LINES:
        return 'Straight line';
      case DRAW_STYLE.CIRCLE:
        return 'Circle';
      case DRAW_STYLE.DASHED_LINES:
        return 'Dashed Line';
      case DRAW_STYLE.RECTANGLE:
        return 'Rectangle';
      case DRAW_STYLE.ELLIPSE:
        return 'Ellipse';
      case 'icon':
        return 'Icon';
      default:
        return 'Freeform';
    }
  };

  const getIsDashedLine = () => {
    if (globalState.projectLayers && globalState.selectedElement.elementUuid && globalState.selectedElement.layerUuid) {
      const selectedLayer = globalState.projectLayers.filter((layer) => layer.uuid === globalState.selectedElement.layerUuid);
      if (selectedLayer.length) {
        const line = selectedLayer[0].drawingLines.filter((dl) => dl[0].lineUuid === globalState.selectedElement.elementUuid);
        if (line.length) { return line[0][0].isDashedLine; }
        const rect = selectedLayer[0].drawingRectangles.filter((dr) => dr.rectangleUuid === globalState.selectedElement.elementUuid);
        if (rect.length) return rect[0].isDashedLine;
        const cirlce = selectedLayer[0].drawingCircles.filter((dc) => dc.circleUuid === globalState.selectedElement.elementUuid);
        if (cirlce.length) return cirlce[0].isDashedLine;
        const ellipse = selectedLayer[0].drawingEllipses.filter((de) => de.ellipseUuid === globalState.selectedElement.elementUuid);
        if (ellipse.length) return ellipse[0].isDashedLine;
      }
    }
    return true;
  };

  const getNumberOfIcons = () => {
    if (globalState.projectLayers && globalState.selectedElement.elementUuid && globalState.selectedElement.layerUuid) {
      const selectedLayer = globalState.projectLayers.filter((layer) => layer.uuid === globalState.selectedElement.layerUuid);
      if (selectedLayer.length) {
        const numberOfIcons = selectedLayer[0].draggedIcons.filter((icon) => icon.lineUuid === globalState.selectedElement.elementUuid);
        return numberOfIcons.length;
      }
    }
    return 0;
  };

  useEffect(async () => {
    if (globalState.selectedElement.elementUuid && globalState.selectedElement.layerUuid) {
      const selectedLayer = globalState.projectLayers.filter((layer) => layer.uuid === globalState.selectedElement.layerUuid);
      if (selectedLayer.length) {
        selectedLayer[0].drawingLines.map((line) => {
          if (!!line.length && line[0].lineUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(line);
            setSelectedElementType(line[0].drawStyle);
            setToolbarTitle(getToolbarTitle(line[0].drawStyle));
          }
          return line;
        });

        selectedLayer[0].layerText.map((text) => {
          if (text.textUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(text);
            setSelectedElementType('text');
            setToolbarTitle('Text');
          }
          return text;
        });

        selectedLayer[0].drawingCircles.map((circle) => {
          if (circle.circleUuid && circle.circleUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(circle);
            setSelectedElementType(circle.drawStyle);
            setToolbarTitle(getToolbarTitle(circle.drawStyle));
          }
          return circle;
        });

        selectedLayer[0].drawingRectangles.map((rectangle) => {
          if (rectangle.rectangleUuid && rectangle.rectangleUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(rectangle);
            setSelectedElementType(rectangle.drawStyle);
            setToolbarTitle(getToolbarTitle(rectangle.drawStyle));
          }
          return rectangle;
        });

        selectedLayer[0].drawingEllipses.map((ellipse) => {
          if (ellipse.ellipseUuid && ellipse.ellipseUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(ellipse);
            setSelectedElementType(ellipse.drawStyle);
            setToolbarTitle(getToolbarTitle(ellipse.drawStyle));
          }
          return ellipse;
        });
        selectedLayer[0].draggedIcons.map((icon) => {
          if (icon.iconUuid && icon.iconUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
            setSelectedElement(icon);
            setSelectedElementType('icon');
            setToolbarTitle(getToolbarTitle('icon'));
            setIconSize(icon.rect.size);
            setIconAngle(icon.rect.rotateAngle);
          }
          return icon;
        });

        const elementIcons = selectedLayer[0].draggedIcons.filter(
          (icon) => icon.lineUuid && icon.lineUuid.toString() === globalState.selectedElement.elementUuid.toString(),
        );
        if (elementIcons.length && elementIcons[0].rect && elementIcons[0].rect.size) setIconSize(elementIcons[0].rect.size);
      }
      const exist = await localActions.checkIfLineHasIcons();
      setAddIconsToLine(exist);
    } else {
      localActions.setShowSelectedObjectToolbar(false);
    }
  }, [globalState.selectedElement.elementUuid]);

  useEffect(async () => {
    if (globalState.updateSelectedElement) {
      await localActions.setUpdateSelectedElement(false);
      if (globalState.selectedElement.elementUuid && globalState.selectedElement.layerUuid) {
        const selectedLayer = globalState.projectLayers.filter((layer) => layer.uuid === globalState.selectedElement.layerUuid);
        if (selectedLayer.length) {
          selectedLayer[0].drawingLines.map((line) => {
            if (!!line.length && line[0].lineUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
              setSelectedElement(line);
              setSelectedElementType(line[0].drawStyle);
              setToolbarTitle(getToolbarTitle(line[0].drawStyle));
            }
            return line;
          });

          selectedLayer[0].drawingCircles.map((circle) => {
            if (circle.circleUuid && circle.circleUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
              setSelectedElement(circle);
              setSelectedElementType(circle.drawStyle);
              setToolbarTitle(getToolbarTitle(circle.drawStyle));
            }
            return circle;
          });

          selectedLayer[0].drawingRectangles.map((rectangle) => {
            if (rectangle.rectangleUuid && rectangle.rectangleUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
              setSelectedElement(rectangle);
              setSelectedElementType(rectangle.drawStyle);
              setToolbarTitle(getToolbarTitle(rectangle.drawStyle));
            }
            return rectangle;
          });

          selectedLayer[0].drawingEllipses.map((ellipse) => {
            if (ellipse.ellipseUuid && ellipse.ellipseUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
              setSelectedElement(ellipse);
              setSelectedElementType(ellipse.drawStyle);
              setToolbarTitle(getToolbarTitle(ellipse.drawStyle));
            }
            return ellipse;
          });

          selectedLayer[0].draggedIcons.map((icon) => {
            if (icon.iconUuid && icon.iconUuid.toString() === globalState.selectedElement.elementUuid.toString()) {
              setIconAngle(icon.rect.rotateAngle || 0);
              setIconSize(icon.rect.size || 0);
            }
            return icon;
          });
        }
      }
    }
  }, [globalState.updateSelectedElement]);

  useEffect(() => {
    if (selectedElement && selectedElement.textUuid) {
      if (selectedElement.width && selectedElement.height) {
        setTextWidth(selectedElement ? selectedElement.width.substring(0, selectedElement.width.length - 2) : 200);
        setTextHeight(selectedElement ? selectedElement.height.substring(0, selectedElement.height.length - 2) : 50);
      }
    }
  }, [selectedElement]);

  useEffect(() => {
    options = [...globalState.filteredIcons.icons.map((icon, index) => ({
      value: index,
      icon: () => (
        <img src={icon.SignImageURL} alt="icon" style={{ objectFit: 'fill', width: '40px', height: '40px' }} />
      ),
      label: `${icon.Name}`,
      index,
      signImageURL: icon.SignImageURL,
    }))];
  }, [globalState.filteredIcons.icons]);

  useEffect(() => {
    if (textWidth && textHeight) {
      localActions.updateText({
        ...selectedElement,
        width: `${textWidth}px`,
        height: `${textHeight}px`,
      });
    }
  }, [textWidth, textHeight]);

  const getElementColor = () => {
    if (!selectedElement) return globalState.brushColor;
    if (selectedElement.constructor === Array) {
      return selectedElement[0].brushColor;
    }
    return selectedElement.brushColor;
  };

  const getView = () => {
    if (selectedElementType === 'text') {
      return (
        <div className="selected-objects-tools-content-container">
          <label htmlFor="check3">
            Set Text Width&nbsp;
            <input
              style={{ width: '70px', marginLeft: '10px', marginBottom: '20px' }}
              type="number"
              id="check3"
              value={textWidth}
              onChange={(e) => setTextWidth(e.target.value)}
            />
          </label>
          <label htmlFor="check3">
            Set Text Height
            <input
              style={{ width: '70px', marginLeft: '10px' }}
              type="number"
              id="check3"
              value={textHeight}
              onChange={(e) => setTextHeight(e.target.value)}
            />
          </label>
        </div>
      );
    } if (selectedElementType === 'icon') {
      return (
        <div className="selected-objects-tools-content-container">
          <label htmlFor="check3">
            Set Icon Size
            <input
              style={{ width: '70px', marginLeft: '10px', marginBottom: '10px' }}
              type="number"
              id="check3"
              value={iconSize}
              onChange={(e) => onIconSizeChanged(e.target.value)}
            />
          </label>

          <label htmlFor="rotation">
            Set Icon Angle
            <input
              style={{ width: '70px', marginLeft: '10px' }}
              type="number"
              id="rotation"
              value={iconAngle}
              onChange={(e) => {
                if (e.target.value > 360 || e.target.value < 0) return;
                onIconAngleChanged(e.target.value);
              }}
            />
          </label>
        </div>
      );
    }
    return (
      <div
        className="selected-objects-tools-content-container"
      >
        {
          (selectedElementType === 'straightLines'
          || selectedElementType === 'polylines'
          || selectedElementType === 'rectangle'
          || selectedElementType === 'ellipse'
          || selectedElementType === 'circle'
          )
          && (
            <label htmlFor="check4">
              <input
                type="checkbox"
                id="check4"
                checked={getIsDashedLine()}
                onChange={() => {
                  localActions.setDashedLine();
                }}
              />
              Dashed Line
            </label>
          )
        }
        <label htmlFor="check3">
          <input
            type="checkbox"
            id="check3"
            checked={addIconsToLine}
            onChange={async () => {
              if (addIconsToLine) {
                localActions.deleteIconsFromLine();
              } else {
                await localActions.deleteIconsFromLine();
                localActions.addIconsToLine(
                  currentIcon.signImageURL
                    ? () => (
                      <img src={currentIcon.signImageURL} alt="icon" style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
                    ) : currentIcon.icon,
                  selectedElement,
                  selectedElementType,
                  globalState.selectedElement.layerUuid,
                  distanceBetweenIcons,
                  iconSize,
                );
              }
              setAddIconsToLine(!addIconsToLine);
            }}
          />
          {`Add Icons to ${toolbarTitle}`}
        </label>
        {addIconsToLine
            && (
              <>
                <label htmlFor="iconsSize">
                  Icons size:
                  <input
                    style={{ width: '70px', marginLeft: '10px' }}
                    type="number"
                    id="iconsSize"
                    // value={iconSize}
                    defaultValue={iconSize}
                    onChange={async (e) => {
                      setIconSize(e.target.value);
                      await localActions.deleteIconsFromLine();
                      localActions.addIconsToLine(
                        currentIcon.signImageURL
                          ? () => (
                            <img src={currentIcon.signImageURL} alt="icon" style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
                          ) : currentIcon.icon,
                        selectedElement,
                        selectedElementType,
                        globalState.selectedElement.layerUuid,
                        distanceBetweenIcons,
                        (e.target.value),
                      );
                    }}
                  />
                </label>
                {
                  (selectedElementType === 'straightLines' || selectedElementType === 'polylines'
            || selectedElementType === 'freeform' || selectedElementType === 'dashedLines')
            && (
              <>
                <label htmlFor="iconsSpacing">
                  Distance Between Icons:
                  <input
                    style={{ width: '70px', marginLeft: '10px', marginBottom: '5px' }}
                    type="number"
                    id="iconsSpacing"
                    defaultValue={distanceBetweenIcons}
                    min={10}
                    onChange={async (e) => {
                      if (Number(e.target.value) < 10) return;
                      setDistanceBetweenIcons(Number(e.target.value));
                      await onSpaceBetweenIconsChange(Number(e.target.value));
                    }}
                  />
                </label>
              </>
            )
                }
                <label htmlFor="numberOfIcons">
                  Number Of Icons:
                  <input
                    style={{ width: '70px', marginLeft: '10px', marginBottom: '5px' }}
                    type="number"
                    id="numberOfIcons"
                    value={getNumberOfIcons()}
                    disabled
                  />
                </label>
                <Select
                  defaultValue={options[0]}
                  options={options}
                  components={{ Option: IconOption }}
                  isDisabled={!addIconsToLine}
                  onChange={async (data) => {
                    setCurrentIcon(data);
                    await localActions.deleteIconsFromLine();
                    localActions.addIconsToLine(
                      currentIcon.signImageURL
                        ? () => (
                          <img src={data.signImageURL} alt="icon" style={{ objectFit: 'fill', width: '100%', height: '100%' }} />
                        ) : currentIcon.icon,
                      selectedElement,
                      selectedElementType,
                      globalState.selectedElement.layerUuid,
                      distanceBetweenIcons,
                      iconSize,
                    );
                  }}
                />
              </>

            )}
        <div className="color">
          <div>Color: </div>
          <div className="action">
            <div className="color-container">
              <div
                className="current-color"
                onClick={() => setOpenColorPicler(true)}
                title="Pick Brush Color"
                style={{ backgroundColor: `${getElementColor()}` }}
              />
              {
                openColorPicker
             && (
               <div className="color-picker-container" ref={ref}>
                 <ColorPicker onChange={(color) => {
                   localActions.changeElementProperty(globalState.selectedElement, 'brushColor', color);
                   setOpenColorPicler(false);
                 }}
                 />
               </div>
             )
              }
            </div>
          </div>
        </div>

        <div className="color">
          <div>Brush size: </div>
          <BrushSizeMenu onChange={(brushSize) => localActions.changeElementProperty(globalState.selectedElement, 'brushSize', brushSize)} />
        </div>
      </div>
    );
  };

  return (
    <Draggable
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: 0, y: 0 }}
      nodeRef={ref}
    >
      <div
        className="selected-objects-tools-container"
        ref={ref}
        style={{
          zIndex: (globalState.projectLayers.length * 2) + FLOATING_TOOLBARS_OFFSET,
        }}
      >
        <strong className="selected-objects-tools-header">
          <div className="title">{toolbarTitle}</div>
          <FaWindowMinimize
            size={15}
            onClick={() => localActions.setShowSelectedObjectToolbar(false)}
          />
        </strong>
        {getView()}

      </div>
    </Draggable>
  );
};

export default SelectedObjectTools;
