/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import './SideBar.scss';

import React, { useState } from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import {
  BsSearch,
} from 'react-icons/bs';
import {
  GiTrafficCone, GiTrafficLightsReadyToGo,
} from 'react-icons/gi';
import {
  TiFolderOpen,
} from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import * as actions from '../../../actions';
import { DRAGGABLE_ICONS } from '../../../lib/constants';
import SaveChangesModal from '../../../shared/Modals/SaveChangesModal/SaveChangesModal';
import DraggableIcon from '../DraggableIcon/DraggableIcon';
import SearchIcons from './SearchIcons';

const draggableIcons = DRAGGABLE_ICONS;

const SideBar = () => {
  const [selectedIcons, setSelectedIcons] = useState(draggableIcons.trafficCones);
  const [activeSidebarItem, setActiveSidebarItem] = useState('searchIcons');
  const dispatch = useDispatch();

  const localActions = {
    getProjects: () => dispatch(actions.project.getProjects()),
    setCurrentProject: (project) => dispatch(actions.project.setCurrentProject(project)),
    deleteProject: (id) => dispatch(actions.project.deleteProject(id)),
  };

  const globalState = {
    allProjects: useSelector((state) => state.project.allProjects),
    isNewProject: useSelector((state) => state.project.isNewProject),
    projectTitle: useSelector((state) => state.project.projectTitle),
    isProjectChanged: useSelector((state) => state.project.isProjectChanged),
  };

  const handleImgClick = (selectedIconsArray, sidebarItem) => {
    setSelectedIcons(selectedIconsArray || []);
    setActiveSidebarItem(sidebarItem);
    if (!selectedIconsArray && sidebarItem !== 'searchIcons') {
      localActions.getProjects();
    }
  };

  const getProjects = () => {
    if (globalState.isNewProject) {
      return [{
        description: 'Description',
        id: '',
        name: globalState.projectTitle,
      }, ...globalState.allProjects];
    }
    return globalState.allProjects;
  };

  return (
    <div className="sidebar">
      <div className="nav-tabs">
        <button
          type="button"
          className={activeSidebarItem === 'project' ? 'img-container-active' : 'img-container'}
          onClick={() => handleImgClick(null, 'project')}
        >
          <TiFolderOpen size={50} />
          <div>Project</div>
        </button>
        <button
          type="button"
          className={activeSidebarItem === 'searchIcons' ? 'img-container-active' : 'img-container'}
          onClick={() => handleImgClick(null, 'searchIcons')}
        >
          <BsSearch size={50} />
          <div>Search Icons</div>
        </button>
      </div>
      {
        activeSidebarItem === 'project'
          ? (
            <div className="projects-list">
              {
                getProjects().map((project) => (
                  <ProjectItem
                    project={project}
                    onClick={(p) => {
                      localActions.setCurrentProject(p);
                    }}
                    onDelete={(id) => localActions.deleteProject(id)}
                    key={Math.random()}
                  />
                ))
              }
            </div>
          )
          : activeSidebarItem !== 'searchIcons' ? (
            <div className="images-grid">
              {
                selectedIcons.map((Icon) => (
                  <DraggableIcon Icon={Icon} key={uuidv4()} />
                ))
              }
            </div>
          ) : (
            <SearchIcons />
          )
      }
    </div>
  );
};

const ProjectItem = ({ project, onClick, onDelete }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const randomString1 = `${btoa(Math.random().toString()).substring(0, 16)}`;

  const globalState = {
    projectId: useSelector((state) => state.project.projectId),
    isProjectChanged: useSelector((state) => state.project.isProjectChanged),
  };

  const localActions = {
    saveProject:
        () => dispatch(actions.project.saveProject()),
  };

  function handleOpenProjectClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (globalState.isProjectChanged) {
      setOpenConfirmationModal(true);
      return;
    }
    onClick(project);
  }

  function handleOnDeleteProjectClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onDelete(project.id);
  }

  return (
    <div style={{ width: '100%' }}>
      <ContextMenuTrigger id={randomString1}>
        <div
          className="sidebar-project-item"
          onClick={() => {
            if (globalState.isProjectChanged) {
              setOpenConfirmationModal(true);
              return;
            }
            onClick(project);
          }}
          style={{ backgroundColor: globalState.projectId === project.id && '#ddd' }}
        >
          <TiFolderOpen size={30} />
          <div className="sidebar-project-item-name">
            {(globalState.projectId === project.id && globalState.isProjectChanged) ? `*${project.name}` : `${project.name}`}

          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={randomString1} style={{ zIndex: 200 }}>
        <MenuItem
          onClick={handleOpenProjectClick}
        >
          Open Project
        </MenuItem>
        <MenuItem
          onClick={handleOnDeleteProjectClick}
        >
          Delete Project
        </MenuItem>
        <MenuItem divider />
      </ContextMenu>
      {
        openConfirmationModal
        && (
          <SaveChangesModal
            closeModal={() => setOpenConfirmationModal(false)}
            showModal={openConfirmationModal}
            onSave={async () => {
              await localActions.saveProject();
              // setOpenConfirmationModal(false);
              await onClick(project);
            }}
            onCancel={async () => {
              await onClick(project);
              // setOpenConfirmationModal(false);
            }}
          />
        )
      }
    </div>
  );
};

export default SideBar;
