/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './DrawingTools.scss';

import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import {
  BiText,
} from 'react-icons/bi';
import {
  FaRegHandRock,
  FaWindowMinimize,
} from 'react-icons/fa';
import {
  HiPencil,
} from 'react-icons/hi';
import {
  VscCircleOutline,
} from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import { ReactComponent as DashedLine } from '../../../img/dashedLine.svg';
import { ReactComponent as Ellipse } from '../../../img/ellipse.svg';
import { ReactComponent as NewImage } from '../../../img/image.svg';
import { ReactComponent as Polyline } from '../../../img/polyline2.svg';
import { ReactComponent as Rectangle } from '../../../img/rectangle.svg';
import { ReactComponent as StraightLine } from '../../../img/straightLine.svg';
import { DRAW_STYLE, FLOATING_TOOLBARS_OFFSET, LAYERS } from '../../../lib/constants';
import BrushSizeMenu from '../../../shared/BrushSizeMenu/BrushSizeMenu';
import ChooseFileInput from '../../../shared/ChooseFileInput';
import ColorPicker from '../../../shared/ColorPicker/ColorPicker';

const DrawingTools = () => {
  const [openColorPicker, setOpenColorPicler] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openColorPicker && ref.current && !ref.current.contains(e.target)) {
        setOpenColorPicler(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openColorPicker]);

  const localActions = {
    setForegroundLayer:
      (foregroundLayer) => dispatch(actions.layers.setForegroundLayer(foregroundLayer)),
    setDrawStyle:
      (drawStyle) => dispatch(actions.drawing.setDrawStyle(drawStyle)),
    setShowDrawingToolbar:
        (showDrawingToolbar) => dispatch(actions.drawing.setShowDrawingToolbar(showDrawingToolbar)),
    setBrushColor: (color) => dispatch(actions.drawing.setBrushColor(color)),
  };

  const globalState = {
    foregroundLayer: useSelector((state) => state.layers.foregroundLayer),
    projectLayers: useSelector((state) => state.layers.projectLayers),
    drawStyle: useSelector((state) => state.drawing.drawStyle),
    brushColor: useSelector((state) => state.drawing.brushColor),
  };

  const switchForegroundLayer = () => {
    // eslint-disable-next-line no-unused-expressions
    globalState.foregroundLayer === LAYERS.DRAWING_LAYER
      ? localActions.setForegroundLayer(LAYERS.DND_LAYER)
      : localActions.setForegroundLayer(LAYERS.DRAWING_LAYER);
  };

  return (
    <Draggable
      bounds="parent"
      handle="strong"
      defaultPosition={{ x: 0, y: 0 }}
      nodeRef={ref}
    >
      <div
        className="drawing-tools-container"
        ref={ref}
        style={{
          zIndex: (globalState.projectLayers.length * 2) + FLOATING_TOOLBARS_OFFSET,
        }}
      >
        <strong>
          <div className="title">Drawing Tools</div>
          <FaWindowMinimize
            size={15}
            onClick={() => localActions.setShowDrawingToolbar(false)}
          />
        </strong>
        <div className="icons-container">

          <div className="action">
            <ChooseFileInput>
              <NewImage width={18} color="#444" title="Open Image" />
            </ChooseFileInput>
          </div>
          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.FREEFORM ? '#eee' : 'unset' }}
          >
            <HiPencil
              size={18}
              color="#444"
              title="Draw Freeform"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.FREEFORM);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.STRAIGHT_LINES ? '#eee' : 'unset' }}
          >
            <StraightLine
              size={18}
              color="#444"
              title="Draw Straingt Lines"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.STRAIGHT_LINES);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.DASHED_LINES ? '#eee' : 'unset' }}
          >
            <DashedLine
              size={18}
              color="#444"
              title="Draw Dashed Lines"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.DASHED_LINES);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.ELLIPSE ? '#eee' : 'unset' }}
          >
            <Ellipse
              size={18}
              color="#444"
              title="Draw Ellipse"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.ELLIPSE);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.RECTANGLE ? '#eee' : 'unset' }}
          >
            <Rectangle
              size={18}
              color="#444"
              title="Draw Rectangle"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.RECTANGLE);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.POLYLINES ? '#eee' : 'unset' }}
          >
            <Polyline
              size={18}
              color="#444"
              title="Draw Polylines"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.POLYLINES);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.CIRCLE ? '#eee' : 'unset' }}
          >
            <VscCircleOutline
              size={180}
              color="#444"
              title="Draw Circle"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.CIRCLE);
              }}
            />
          </div>

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.WRITE_TEXT ? '#eee' : 'unset' }}
          >
            <BiText
              size={18}
              color="#444"
              title="Write Text"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.WRITE_TEXT);
              }}
            />
          </div>

          <div className="action">
            <div className="color-container">
              <div
                className="current-color"
                onClick={() => setOpenColorPicler(true)}
                title="Pick Brush Color"
                style={{ backgroundColor: `${globalState.brushColor}` }}
              />
            </div>
          </div>

          {
            openColorPicker
             && (
               <div className="color-picker-container" ref={ref}>
                 <ColorPicker onChange={(colorHex) => {
                   localActions.setBrushColor(colorHex);
                   setOpenColorPicler(false);
                 }}
                 />
               </div>
             )
          }
          <BrushSizeMenu />

          <div
            className="action"
            style={{ backgroundColor: globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT ? '#eee' : 'unset' }}
          >
            <FaRegHandRock
              size={18}
              color="#444"
              title="Select Element"
              onClick={() => {
                switchForegroundLayer();
                localActions.setDrawStyle(DRAW_STYLE.SELECT_ELEMENT);
              }}
            />
          </div>

        </div>

      </div>
    </Draggable>
  );
};

export default DrawingTools;
