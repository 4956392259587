import axios from 'axios';

const UserService = {
  GetUserToken: async (username, password) => {
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
    const response = await axios.get('http://api.portal.roads.dk/api/v1/Authentication/Authenticate', {
      headers: {
        Authorization: `Basic ${token}`,
      },
    });
    return response.data;
  },
};

export default UserService;
