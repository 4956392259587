/* eslint-disable max-len */
import './ImageEditor.scss';

import Ruler from '@scena/react-ruler';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { v4 as uuidv4 } from 'uuid';

import * as actions from '../../../actions';
import {
  ZOOM_ACTIONS,
} from '../../../lib/constants';
import DeleteElementModal from '../../../shared/Modals/DeleteElementModal/DeleteElmentModal';
import EditorPageHeader from '../EditorPageHeader/EditorPageHeader';
import ImageLayer from '../ImageLayer/ImageLayer';
import Layer from '../Layer/Layer';
import SideBar from '../SideBar/SideBar';

const ImageEditor = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [posHorizontal, setPosHorizontal] = useState(0);
  const [posVertical, setPosVertical] = useState(0);

  const globalState = {
    imageUrl: useSelector((state) => state.image.imageUrl),
    imageData: useSelector((state) => state.image.imageData),
    zoomAction: useSelector((state) => state.image.zoomAction),
    projectLayers: useSelector((state) => state.layers.projectLayers),
    openDeleteElementModal: useSelector((state) => state.layers.openDeleteElementModal),
    drawStyle: useSelector((state) => state.drawing.drawStyle),
    projectTitle: useSelector((state) => state.project.projectTitle),
  };

  const localActions = {
    setZoomAction:
      (zoomAction) => dispatch(actions.image.setZoomAction(zoomAction)),
    setZoomScale:
      (scale) => dispatch(actions.image.setZoomScale(scale)),
    removeElementFromLayer: () => dispatch(actions.layers.removeElementFromLayer()),
    setOpenDeleteElementModal:
      (openDeleteElementModal) => dispatch(actions.layers.setOpenDeleteElementModal(openDeleteElementModal)),
    deleteIconsFromLine:
        () => dispatch(actions.drawing.deleteIconsFromLine()),
    setShowSelectedObjectToolbar:
      (showSelectedObjectTools) => dispatch(actions.drawing.setShowSelectedObjectToolbar(showSelectedObjectTools)),
  };

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    if (globalState.zoomAction) {
      if (globalState.zoomAction === ZOOM_ACTIONS.ZOOM_IN) {
        ref.current.zoomIn();
        setTimeout(() => {
          localActions.setZoomScale(ref.current.state.scale);
        }, 400);
      } else if (globalState.zoomAction === ZOOM_ACTIONS.ZOOM_OUT) {
        ref.current.zoomOut();
        setTimeout(() => {
          localActions.setZoomScale(ref.current.state.scale);
        }, 400);
      } else if (globalState.zoomAction === ZOOM_ACTIONS.ZOOM_CENTER) {
        ref.current.centerView();
      } else {
        ref.current.resetTransform();
        setTimeout(() => {
          localActions.setZoomScale(1);
        }, 400);
      }
      localActions.setZoomAction('');
    }
  }, [globalState.zoomAction]);

  const onDeletePressed = async (e) => {
    if (e.key === 'Delete') {
      localActions.setOpenDeleteElementModal(true);
    }
    if (e.keyCode === 27) {
      localActions.setShowSelectedObjectToolbar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onDeletePressed);
    return () => {
      document.removeEventListener('keydown', onDeletePressed);
    };
  }, []);

  const getScrollPos = (x, y) => {
    setPosHorizontal(x);
    setPosVertical(y);
  };

  return (
    <div className="image-editor-container">
      <SideBar />
      <div className="grid-container" id="editor-page">
        <div style={{
          width: '27px', height: '27px', position: 'absolute', left: 0, top: 0, backgroundColor: '#ddd',
        }}
        />
        <div
          style={{
            width: 'calc(100% - 27px)',
            height: '27px',
            position: 'absolute',
            left: '27px',
            top: 0,
          }}
        >
          <Ruler
            type="horizontal"
            backgroundColor="#ddd"
            textColor="#333"
            textAlign="center"
            mainLineSize="50%"
            scrollPos={posHorizontal}
          />
        </div>
        <div
          style={{
            height: 'calc(100% - 27px)',
            width: '27px',
            position: 'absolute',
            left: 0,
            top: '27px',
          }}
        >
          <Ruler
            type="vertical"
            backgroundColor="#ddd"
            textColor="#333"
            textAlign="center"
            mainLineSize="50%"
            scrollPos={posVertical}
          />
        </div>
        <EditorPageHeader />
        <div className="editor-page-layer-container">
          <TransformWrapper
            // defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
            options={{ defaultScale: 1 }}
            ref={ref}
            disabled={globalState.drawStyle}
          >
            {() => (
              <>

                <TransformComponent>

                  <ImageLayer />
                  {
                    globalState.projectLayers.map((layer, index) => {
                      if (!layer.isShown) return null;

                      return (
                        <Layer
                          getScrollPos={(x, y) => getScrollPos(x, y)}
                          title={layer.title}
                          isShown={layer.isShown}
                          zIndex={((globalState.projectLayers.length - index) * 2) + 1}
                          key={uuidv4()}
                          uuid={layer.uuid}
                          draggedIcons={layer.draggedIcons}
                          drawingLines={layer.drawingLines}
                          drawingEllipses={layer.drawingEllipses}
                          drawingRectangles={layer.drawingRectangles}
                          drawingCircles={layer.drawingCircles}
                          layerText={layer.layerText}
                          isTopLayer={index === 0}
                        />
                      );
                    })
                  }

                </TransformComponent>

              </>
            )}
          </TransformWrapper>
        </div>
      </div>
      {
        globalState.openDeleteElementModal
        && <DeleteElementModal />
      }
    </div>
  );
};

export default ImageEditor;
