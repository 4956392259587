/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import './Layer.scss';

import React, { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import { DEFAULT_SVG_ICON_SIZE } from '../../../lib/constants';
import { ItemTypes } from '../../../lib/Items';
import DrawingLayer from '../DrawingLayer/DrawingLayer';
import DroppedIcon from '../DroppedIcon/DroppedIcon';

const Layer = ({
  zIndex, isShown, uuid, draggedIcons = [], drawingLines, drawingCircles, layerText, drawingEllipses, drawingRectangles, getScrollPos,
}) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const localActions = {
    setDraggedIcons:
        (icons, layerUuid) => dispatch(actions.layers.setDraggedIcons(icons, layerUuid)),
    setSelectedEleementUuid:
        (elementUuid, layerUuid) => dispatch(actions.layers.setSelectedEleementUuid(elementUuid, layerUuid)),
  };

  const globalState = {
    zoomScale: useSelector((state) => state.image.zoomScale),
    currentElementGroup: useSelector((state) => state.layers.currentElementGroup),
    elementGroups: useSelector((state) => state.layers.elementGroups),
    canvasSize: useSelector((state) => state.layers.canvasSize),
  };

  const [, drop] = useDrop({
    accept: ItemTypes.DRAGGABLE_ICON,
    drop: (item, monitor) => {
      const targetRect = containerRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const rect = {
        top: (clientOffset.y - targetRect.y) / globalState.zoomScale,
        left: (clientOffset.x - targetRect.x) / globalState.zoomScale,
        leftPercentage: Math.ceil(((clientOffset.x - targetRect.x) / targetRect.width) * 100),
        topPercentage: Math.ceil(((clientOffset.y - targetRect.y) / targetRect.height) * 100),
        size: DEFAULT_SVG_ICON_SIZE,
        rotateAngle: 0,
      };
      const elementGroup = globalState.currentElementGroup;
      localActions.setDraggedIcons([...draggedIcons, { ...item, rect, elementGroup }], uuid);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const selectElement = (elementUuid) => {
    localActions.setSelectedEleementUuid(elementUuid, uuid);
  };

  const onIconMoved = (data, iconUuid) => {
    if (!data) return;
    const newDraggedIcons = [...draggedIcons];
    for (let i = 0; i < newDraggedIcons.length; i++) {
      const icon = newDraggedIcons[i];
      if (icon.iconUuid === iconUuid) {
        const targetRect = containerRef.current.getBoundingClientRect();
        const rect = {
          ...icon.rect,
          top: Math.abs(icon.rect.top + data.y),
          left: Math.abs(icon.rect.left + data.x),
          leftPercentage: ((Math.abs(icon.rect.left + data.x) / targetRect.width) * 100) * globalState.zoomScale,
          topPercentage: (Math.abs(icon.rect.top + data.y) / targetRect.height) * 100 * globalState.zoomScale,
        };

        icon.rect = rect;
      }
    }
    localActions.setDraggedIcons([...newDraggedIcons], uuid);
  };

  useEffect(() => {
    const el = document.getElementById('canvas-container');
    const target = el.getBoundingClientRect();
    getScrollPos(-target.left + 344, -target.top + 115);
  }, []);

  useEffect(() => {
    const el = document.getElementById('canvas-container');
    const target = el.getBoundingClientRect();
    getScrollPos(-target.left + 344, -target.top + 115);
  }, [globalState.canvasSize]);

  return (
    <>
      {isShown
        ? (
          <div
            className="layer-container"
            ref={containerRef}
            style={{ zIndex }}
            id={`layer_div_${uuid}`}
          >
            <div
              className="dnd-drop-container"
              id="drop-container"
              ref={drop}
            >
              {
                draggedIcons.map(({
                  Icon, rect, iconUuid, elementGroup,
                }) => {
                  const eg = globalState.elementGroups.filter((e) => e.uuid === elementGroup.uuid);
                  if (!eg.length || eg[0].isShown === false || !Icon) return null;
                  return (
                    <DroppedIcon
                      Icon={Icon}
                      rect={rect}
                      key={iconUuid}
                      zIndex={zIndex}
                      onIconMoved={onIconMoved}
                      uuid={iconUuid}
                      selectElement={selectElement}
                    />
                  );
                })
              }
              <DrawingLayer
                layerUuid={uuid}
                drawingLines={drawingLines}
                zIndex={zIndex}
                drawingCircles={drawingCircles}
                drawingEllipses={drawingEllipses}
                drawingRectangles={drawingRectangles}
                layerText={layerText}
              />
            </div>
          </div>
        )
        : null}
    </>
  );
};

export default Layer;
