import './MenuBar.scss';

import React from 'react';
import {
  Container, Nav, Navbar, NavDropdown,
} from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import {
  ZOOM_ACTIONS,
} from '../../../lib/constants';
import { exportAsImage, exportAsPdf } from '../../../lib/utils';

const MenuBar = () => {
  const dispatch = useDispatch();
  const localActions = {
    setShowAddLayerModal:
        (showAddLayerModal) => dispatch(actions.layers.setShowAddLayerModal(showAddLayerModal)),
    setShowManageLayersModal:
        // eslint-disable-next-line max-len
        (showManageLayersModal) => dispatch(actions.layers.setShowManageLayersModal(showManageLayersModal)),
    setShowLayersToolbar:
        (showLayersToolbar) => dispatch(actions.layers.setShowLayersToolbar(showLayersToolbar)),
    setShowDrawingToolbar:
        (showDrawingToolbar) => dispatch(actions.drawing.setShowDrawingToolbar(showDrawingToolbar)),
    saveProject:
        () => dispatch(actions.project.saveProject()),
    createNewProject:
        () => dispatch(actions.project.createNewProject()),
    setZoomAction:
        (zoomAction) => dispatch(actions.image.setZoomAction(zoomAction)),
  };

  const globalState = {
    projectTitle: useSelector((state) => state.project.projectTitle),
    isNewProject: useSelector((state) => state.project.isNewProject),
  };

  return (
    <div>
      <Container fluid>
        <Navbar expand="lg" style={{ padding: '0' }}>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdownMenu title="File" id="basic-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => localActions.createNewProject()}
                >
                  New Project
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => localActions.saveProject()}
                >
                  Save Project
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <DropdownSubmenu title="Export">
                  <NavDropdown.Item
                    onClick={() => exportAsPdf('editor-page', globalState.projectTitle)}
                  >
                    PDF...
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => exportAsImage('editor-page', globalState.projectTitle, 'png')}
                  >
                    PNG...
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => exportAsImage('editor-page', globalState.projectTitle, 'image/jpeg')}
                  >
                    JPEG...

                  </NavDropdown.Item>
                </DropdownSubmenu>
              </NavDropdownMenu>
              <NavDropdownMenu title="Format" id="basic-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_IN)}
                >
                  Zoom In

                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_OUT)}
                >
                  Zoom Out

                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_CENTER)}
                >
                  Center Image

                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_RESET)}
                >
                  Reset Zoom

                </NavDropdown.Item>
              </NavDropdownMenu>
              <NavDropdownMenu title="View" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => localActions.setShowDrawingToolbar(true)}>
                  Drawing Tools
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => localActions.setShowLayersToolbar(true)}>
                  Layers
                </NavDropdown.Item>
              </NavDropdownMenu>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>

    </div>
  );
};

export default MenuBar;
