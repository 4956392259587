import './EditorPage.scss';

import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loader } from 'simple-react-loader';

import DrawingTools from './DrawingTools/DrawingTools';
import ImageEditor from './ImageEditor/ImageEditor';
import LayersTools from './LayersTools/LayersTools';
import MenuBar from './MenuBar/MenuBar';
import SelectedObjectsToolbar from './SelectedObjectsTools/SelectedObjectsTools';
import ToolBar from './ToolBar/ToolBar';

const EditorPage = () => {
  const [cookies] = useCookies([]);
  const history = useHistory();
  const globalState = {
    showLayersToolbar: useSelector((state) => state.layers.showLayersToolbar),
    showDrawingToolbar: useSelector((state) => state.drawing.showDrawingToolbar),
    showSelectedObjectTools: useSelector((state) => state.drawing.showSelectedObjectTools),
    isLoading: useSelector((state) => state.project.isLoading),
  };

  useEffect(() => {
    if (!cookies || !cookies.currentUser || !cookies.currentUser.Token) {
      history.push('/login');
    }
  }, []);

  return (
    <div className="editor-page-container">
      {
        globalState.isLoading && <Loader showLoader={false} type="refresh" />
      }
      <MenuBar />
      <ToolBar />
      <ImageEditor />
      {
        globalState.showDrawingToolbar && <DrawingTools />
      }
      {
        globalState.showLayersToolbar && <LayersTools />
      }
      {
        globalState.showSelectedObjectTools && <SelectedObjectsToolbar />
      }
    </div>
  );
};

export default EditorPage;
