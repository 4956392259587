/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  BiHide,
  BiShow,
} from 'react-icons/bi';

import { ItemTypes } from '../../../lib/Items';

const ElementGroup = ({
  index, elementGroup, isSelected, onSelect,
  sortLayersList, onChangeTitle, setElementGroupVisability,
}) => {
  const [elementGroupTitle, setElementGroupTitle] = useState('');
  const [, drag] = useDrag({
    type: ItemTypes.DRAGGABLE_ELEMENT_GROUP,
    item: {
      index,
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.DRAGGABLE_ELEMENT_GROUP,
    drop: (item) => {
      sortLayersList(item.index, index);
    },
  });

  useEffect(() => {
    onChangeTitle(elementGroupTitle, elementGroup.uuid);
  }, [elementGroupTitle]);

  useEffect(() => {
    setElementGroupTitle(elementGroup.name);
  }, []);

  return (
    <div ref={drop}>
      <div
        onClick={() => onSelect()}
        className={isSelected ? 'layers-tool-element-group-item selected' : 'layers-tool-element-group-item'}
        ref={drag}
      >
        <div className="title">
          <input
            value={elementGroupTitle}
            onChange={(e) => setElementGroupTitle(e.target.value)}
          />
        </div>
        {
          elementGroup.isShown
            ? (
              <BiShow
                size={45}
                title="Hide Layer"
                fill="#00A9E2"
                onClick={() => setElementGroupVisability(false, elementGroup.uuid)}
              />
            )
            : (
              <BiHide
                size={45}
                title="Show Layer"
                fill="#00A9E2"
                onClick={() => setElementGroupVisability(true, elementGroup.uuid)}
              />
            )
        }
      </div>
    </div>
  );
};

export default ElementGroup;
