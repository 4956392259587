import './DraggableIcon.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_SVG_ICON_SIZE } from '../../../lib/constants';
import { ItemTypes } from '../../../lib/Items';

const DraggableIcon = ({ Icon }) => {
  const [iconUuid] = useState(uuidv4());
  const [, drag] = useDrag({
    type: ItemTypes.DRAGGABLE_ICON,
    item: {
      Icon,
      iconUuid,
    },
  });

  return (
    <div className="single-icon-container" key={iconUuid} ref={drag}>
      <Icon size={DEFAULT_SVG_ICON_SIZE} />
    </div>
  );
};

DraggableIcon.propTypes = {
  Icon: PropTypes.elementType.isRequired,
};

export default DraggableIcon;
