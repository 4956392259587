/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './DroppedIcon.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ResizableRect from 'react-resizable-rotatable-draggable';

import * as actions from '../../../actions';
import { DEFAULT_SVG_ICON_SIZE, DRAW_STYLE } from '../../../lib/constants';

const DroppedIcon = ({
  Icon, rect, zIndex, onIconMoved, uuid, selectElement,
}) => {
  const [rAngle, setRAngle] = useState(rect.angle);
  const [isDragging, setIsDragging] = useState(false);
  const [iconSize, setIconSize] = useState(rect.size);
  const [resizeData, setResizeData] = useState(null);
  const [data, setData] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
    zoomScale: useSelector((state) => state.image.zoomScale),
    projectLayers: useSelector((state) => state.layers.projectLayers),
    drawStyle: useSelector((state) => state.drawing.drawStyle),
  };

  const localActions = {
    changeIconRotation:
        (angle, iconUuid) => dispatch(actions.layers.changeIconRotation(angle, iconUuid)),
    changeIconSizeAndMove:
        (size, iconUuid, top, left) => dispatch(actions.layers.changeIconSizeAndMove(size, iconUuid, top, left)),
  };

  const handleDragStop = () => {
    onIconMoved(data, uuid);
  };

  const onDrag = (deltaX, deltaY) => {
    if (isDragging) {
      if (data) {
        setData({
          x: (data.x + (deltaX / globalState.zoomScale)),
          y: (data.y + (deltaY / globalState.zoomScale)),
        });
      } else {
        setData({ x: deltaX, y: deltaY });
      }
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const handleResize = (style) => {
    setResizeData({ top: style.top, left: style.left });
    setIconSize(style.height);
  };

  const handleResizeEnd = () => {
    if (resizeData === null || !iconSize) return;
    localActions.changeIconSizeAndMove(Number(iconSize), uuid, Number(resizeData.top), Number(resizeData.left));
  };

  useEffect(() => {
    globalState.projectLayers[0].draggedIcons.map((icon) => {
      if (icon.iconUuid.toString() === uuid.toString()) {
        setRAngle(icon.rect.rotateAngle || 0);
      }
    });
  }, [globalState.projectLayers]);

  return (
    <>
      <div
        key={uuid}
        style={{
          top: rect.top,
          left: rect.left,
          zIndex: `${zIndex + 1}`,
          cursor: 'pointer',
          height: `${rect.size + 2}px`,
          width: `${rect.size + 2}px`,
          border:
            '1px solid transparent',
          transform: `rotate(${rAngle || rect.rotateAngle}deg)`,
        }}
        className="dropped-icon"
        ref={ref}
        onClick={() => {
          if (globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT) { selectElement(uuid); }
        }}
      >
        <Icon />
      </div>

      {(globalState.selectedElement.elementUuid
      && uuid.toString() === globalState.selectedElement.elementUuid.toString())
        && (
          <ResizableRect
            left={resizeData?.left || (data ? rect.left + data.x : rect.left)}
            top={resizeData?.top || (data ? rect.top + data.y : rect.top)}
            width={iconSize || (rect.size ? rect.size : DEFAULT_SVG_ICON_SIZE)}
            height={iconSize || (rect.size ? rect.size : DEFAULT_SVG_ICON_SIZE)}
            rotateAngle={rAngle || rect.rotateAngle}
            zoomable="n, w, s, e, nw, ne, se, sw"
            rotatable
            onRotate={(angle) => {
              setRAngle(angle);
            }}
            onRotateEnd={async () => {
              await localActions.changeIconRotation(rAngle, uuid);
            }}
            aspectRatio={1}
            onDrag={onDrag}
            onDragEnd={handleDragStop}
            onResize={handleResize}
            onResizeEnd={handleResizeEnd}
            onDragStart={onDragStart}
          />
        )}
    </>

  );
};

DroppedIcon.propTypes = {
  Icon: PropTypes.func.isRequired,
  rect: PropTypes.objectOf(PropTypes.number).isRequired,
  zIndex: PropTypes.number.isRequired,
  onIconMoved: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  selectElement: PropTypes.func.isRequired,
};

export default DroppedIcon;
