import 'react-datepicker/dist/react-datepicker.css';
import './EditorPageHeader.scss';

import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import { HEADER_OFFSET } from '../../../lib/constants';

const EditorPageHeader = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [title, setTitle] = useState(`${'New Project'}-${new Date().toDateString()}`);
  const ref = useRef(null);

  const localActions = {
    setProjectTitle:
        (projectTitle) => dispatch(actions.project.setProjectTitle(projectTitle)),
  };

  const globalState = {
    projectTitle: useSelector((state) => state.project.projectTitle),
    projectLayers: useSelector((state) => state.layers.projectLayers),
  };

  useEffect(() => {
    localActions.setProjectTitle(title);
  }, [title]);

  return (
    <Draggable
      bounds="body"
      defaultPosition={{ x: 0, y: 0 }}
      nodeRef={ref}
    >
      <div
        className="editor-page-header"
        style={{
          zIndex: (globalState.projectLayers.length * 2) + HEADER_OFFSET,
        }}
      >
        <div className="title">
          <div>Title:</div>
          <input className="header-input" value={globalState.projectTitle} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="date">
          <div>Date:</div>
          <DatePicker selected={date} onChange={(newDate) => setDate(newDate)} dateFormat="MMMM d, yyyy" />
        </div>
      </div>
    </Draggable>
  );
};

export default EditorPageHeader;
