/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import './DrawingLayer.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import * as actions from '../../../actions';
import {
  DEFAULT_STROKE_DASH_LINE,
  DRAW_STYLE,
} from '../../../lib/constants';

const DrawingLayer = ({
  layerUuid, drawingLines, zIndex, drawingCircles, layerText, drawingEllipses, drawingRectangles,
}) => {
  const [lines, setLines] = useState(drawingLines);
  const [circles, setCircles] = useState(drawingCircles);
  const [ellipses, setEllipses] = useState(drawingEllipses);
  const [rectangles, setRectangles] = useState(drawingRectangles);
  const [text, setText] = useState(layerText);
  const [lineUuid, setLineUuid] = useState(uuidv4);
  const [isDrawingFreeform, setIsDrawingFreeform] = useState(false);
  const [isDrawingStraightLines, setIsDrawingStraightLines] = useState(false);
  const [isWritingText, setIsWritingText] = useState(false);
  const [isDrawingCircle, setIsDrawingCircle] = useState(false);
  const [isDrawingEllipse, setIsDrawingEllipse] = useState(false);
  const [isDrawingRectangle, setIsDrawingRectangle] = useState(false);
  const [isSelectingElement, setIsSelectingElement] = useState(false);
  const [selectedElementStartingPoint, setSelectedElementStartingPoint] = useState(null);
  const [selectedElementStoppingPoint, setSelectedElementStoppingPoint] = useState(null);
  const [isDrawingPolyline, setIsDrawingPolyline] = useState(false);
  const [updateRedux, setUpdateRedux] = useState(false);
  const drawAreaRef = useRef();
  const dispatch = useDispatch();

  const localActions = {
    setDrawingLines:
        (newLines, uuid) => dispatch(actions.layers.setDrawingLines(newLines, uuid)),
    setDrawingCircles:
        (newCircles, uuid) => dispatch(actions.layers.setDrawingCircles(newCircles, uuid)),
    setDrawingEllipses:
        (newEllipses, uuid) => dispatch(actions.layers.setDrawingEllipses(newEllipses, uuid)),
    setDrawingRectangles:
        (newRectangles, uuid) => dispatch(actions.layers.setDrawingRectangles(newRectangles, uuid)),
    setWritingText:
        (newText, uuid, selectedUuid) => dispatch(actions.layers.setWritingText(newText, uuid, selectedUuid)),
    setDrawingPolylines:
        (newPolylines, uuid) => dispatch(actions.drawing.SetDrawingPolylines(newPolylines, uuid)),
    setSelectedEleementUuid:
        (elementUuid, currentLayerUuid) => dispatch(actions.layers.setSelectedEleementUuid(elementUuid, currentLayerUuid)),
    moveElement:
        (startPoint, endPoint) => dispatch(actions.layers.moveElement(startPoint, endPoint)),
    setShowSelectedObjectToolbar:
      (showSelectedObjectTools) => dispatch(actions.drawing.setShowSelectedObjectToolbar(showSelectedObjectTools)),
  };

  const globalState = {
    foregroundLayer: useSelector((state) => state.layers.foregroundLayer),
    brushColor: useSelector((state) => state.drawing.brushColor),
    brushSize: useSelector((state) => state.drawing.brushSize),
    drawStyle: useSelector((state) => state.drawing.drawStyle),
    projectLayers: useSelector((state) => state.layers.projectLayers),
    zoomScale: useSelector((state) => state.image.zoomScale),
    currentElementGroup: useSelector((state) => state.layers.currentElementGroup),
    selectedElement: useSelector((state) => state.layers.selectedElement),
  };

  const onSelected = (elementUuid) => {
    localActions.setSelectedEleementUuid(elementUuid, layerUuid);
  };

  const relativeCoordinatesForEvent = (mouseEvent) => {
    const boundingRect = drawAreaRef.current.getBoundingClientRect();
    if (globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT) {
      return {
        x: (mouseEvent.clientX - boundingRect.left),
        y: (mouseEvent.clientY - boundingRect.top),
        brushColor: globalState.brushColor,
        brushSize: globalState.brushSize,
        drawStyle: globalState.drawStyle,
        lineUuid,
        isDashedLine: globalState.drawStyle === DRAW_STYLE.DASHED_LINES,
        elementGroup: globalState.currentElementGroup,
      };
    }
    if (globalState.drawStyle === DRAW_STYLE.WRITE_TEXT) {
      return {
        x: (mouseEvent.clientX - boundingRect.left) / globalState.zoomScale,
        y: (mouseEvent.clientY - boundingRect.top) / globalState.zoomScale,
        width: '200px',
        height: '50px',
        fontSize: '30px',
        color: 'black',
        fontFamily: '',
        textUuid: uuidv4(),
        value: 'Add text here...',
        elementGroup: globalState.currentElementGroup,
      };
    }
    return {
      x: (mouseEvent.clientX - boundingRect.left) / globalState.zoomScale,
      y: (mouseEvent.clientY - boundingRect.top) / globalState.zoomScale,
      brushColor: globalState.brushColor,
      brushSize: globalState.brushSize,
      drawStyle: globalState.drawStyle,
      lineUuid,
      isDashedLine: globalState.drawStyle === DRAW_STYLE.DASHED_LINES,
      elementGroup: globalState.currentElementGroup,
    };
  };

  const handleMouseDown = (mouseEvent) => {
    if (globalState.drawStyle === DRAW_STYLE.WRITE_TEXT) {
      if (mouseEvent.target instanceof HTMLInputElement) {
        setIsWritingText(true);
      } else if (mouseEvent.target.toString().includes('SVGSVGElement')) {
        localActions.setShowSelectedObjectToolbar(false);
        setIsWritingText(false);
        localActions.setWritingText(text, layerUuid);
      }
    }
    if (globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT) {
      if (mouseEvent.target.toString().includes('SVGLineElement')
      || mouseEvent.target.toString().includes('SVGPathElement')
      || mouseEvent.target.toString().includes('SVGPolylineElement')
      || mouseEvent.target.toString().includes('SVGEllipseElement')
      || mouseEvent.target.toString().includes('SVGRectElement')
      || mouseEvent.target.toString().includes('SVGCircleElement')
      || mouseEvent.target instanceof HTMLInputElement
      ) {
        if (mouseEvent.target instanceof HTMLInputElement) setIsWritingText(false);
        setIsSelectingElement(true);
        setSelectedElementStartingPoint(relativeCoordinatesForEvent(mouseEvent));
        return;
      }
      if (mouseEvent.target.toString().includes('SVGSVGElement')) {
        localActions.setShowSelectedObjectToolbar(false);
      }
    }
    if (
      (mouseEvent.button === 2 || mouseEvent.button === 1)
      && globalState.drawStyle === DRAW_STYLE.POLYLINES
    ) {
      setIsDrawingPolyline(false);
      setUpdateRedux(true);
    }
    if (mouseEvent.button !== 0 || isWritingText) {
      return;
    }

    const point = relativeCoordinatesForEvent(mouseEvent);

    if (globalState.drawStyle === DRAW_STYLE.CIRCLE) {
      setCircles([...circles, { ...point, circleUuid: uuidv4(), endPoint: point }]);
    } else if (globalState.drawStyle === DRAW_STYLE.ELLIPSE) {
      setEllipses([...ellipses, { ...point, ellipseUuid: uuidv4(), endPoint: point }]);
    } else if (globalState.drawStyle === DRAW_STYLE.RECTANGLE) {
      setRectangles([...rectangles, { ...point, rectangleUuid: uuidv4(), endPoint: point }]);
    } else if (globalState.drawStyle === DRAW_STYLE.POLYLINES) {
      if (!isDrawingPolyline) {
        setLines([...lines, [point]]);
      } else {
        const newLines = [...lines];
        newLines[newLines.length - 1] = [...newLines[newLines.length - 1], point];
        setLines(newLines);
      }
    } else if (globalState.drawStyle === DRAW_STYLE.WRITE_TEXT) {
      if (isWritingText || mouseEvent.target instanceof HTMLInputElement || globalState.selectedElement.elementUuid) return;
      localActions.setWritingText([...text, point], layerUuid, point.textUuid);
    } else {
      setLines([...lines, [point]]);
    }

    if (globalState.drawStyle === DRAW_STYLE.FREEFORM) {
      setIsDrawingFreeform(true);
    } else if (globalState.drawStyle === DRAW_STYLE.STRAIGHT_LINES || globalState.drawStyle === DRAW_STYLE.DASHED_LINES) {
      setIsDrawingStraightLines(true);
    } else if (globalState.drawStyle === DRAW_STYLE.POLYLINES) {
      setIsDrawingPolyline(true);
    } else if (globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT) {
      setIsSelectingElement(true);
    } else if (globalState.drawStyle === DRAW_STYLE.CIRCLE) {
      setIsDrawingCircle(true);
    } else if (globalState.drawStyle === DRAW_STYLE.ELLIPSE) {
      setIsDrawingEllipse(true);
    } else if (globalState.drawStyle === DRAW_STYLE.RECTANGLE) {
      setIsDrawingRectangle(true);
    } else {
      setIsWritingText(true);
    }
  };

  const handleMouseMove = (mouseEvent) => {
    if (isDrawingFreeform || isDrawingStraightLines) {
      const point = relativeCoordinatesForEvent(mouseEvent);

      const newLines = [...lines];
      newLines[newLines.length - 1] = [...newLines[newLines.length - 1], point];
      setLines(newLines);
    } else if (isDrawingCircle) {
      const point = relativeCoordinatesForEvent(mouseEvent);

      const newCircles = [...circles];
      newCircles[newCircles.length - 1] = { ...newCircles[newCircles.length - 1], endPoint: point };
      setCircles(newCircles);
    } else if (isDrawingEllipse) {
      const point = relativeCoordinatesForEvent(mouseEvent);

      const newEllipses = [...ellipses];
      newEllipses[newEllipses.length - 1] = { ...newEllipses[newEllipses.length - 1], endPoint: point };
      setEllipses(newEllipses);
    } else if (isDrawingRectangle) {
      const point = relativeCoordinatesForEvent(mouseEvent);

      const newRectangles = [...rectangles];
      newRectangles[newRectangles.length - 1] = { ...newRectangles[newRectangles.length - 1], endPoint: point };
      setRectangles(newRectangles);
    } else if (isSelectingElement && selectedElementStartingPoint !== null) {
      const point = relativeCoordinatesForEvent(mouseEvent);
      setSelectedElementStoppingPoint(point);
    }
  };

  const handleMouseUp = () => {
    if (globalState.drawStyle !== DRAW_STYLE.POLYLINES
      && globalState.drawStyle !== DRAW_STYLE.SELECT_ELEMENT
      && globalState.drawStyle !== DRAW_STYLE.WRITE_TEXT) {
      setIsDrawingFreeform(false);
      setIsDrawingStraightLines(false);
      setIsDrawingCircle(false);
      setIsDrawingEllipse(false);
      setIsDrawingRectangle(false);
      setUpdateRedux(true);
    }
    if (globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT) {
      if (selectedElementStoppingPoint !== null && selectedElementStoppingPoint !== null) {
        localActions.moveElement(selectedElementStartingPoint, selectedElementStoppingPoint);
      }

      setSelectedElementStoppingPoint(null);
      setSelectedElementStartingPoint(null);
    }
  };

  const handleTextChange = (value, textUuid) => {
    const newText = text.map((t) => ({
      ...t,
      value: textUuid === t.textUuid ? value : t.value,
    }));

    setText(newText);
  };

  useEffect(() => {
    if (updateRedux) {
      setUpdateRedux(false);
      localActions.setDrawingLines(lines, layerUuid);
      localActions.setDrawingCircles(circles, layerUuid);
      localActions.setDrawingEllipses(ellipses, layerUuid);
      localActions.setDrawingRectangles(rectangles, layerUuid);
    }
  }, [updateRedux]);

  useEffect(() => {
    setLineUuid(uuidv4());
  }, [isDrawingPolyline, isWritingText, isDrawingStraightLines, isDrawingFreeform, isDrawingCircle, isDrawingEllipse, isDrawingRectangle]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="draw-area"
      ref={drawAreaRef}
      onMouseDown={(e) => handleMouseDown(e)}
      onMouseMove={(e) => handleMouseMove(e)}
      onMouseUp={(e) => handleMouseUp(e)}
      style={{
        cursor: `${globalState.drawStyle === 'selectElement' ? 'pointer' : 'crosshair'}`,
      }}
    >
      <Drawing
        lines={lines}
        circles={circles}
        text={text}
        ellipses={ellipses}
        rectangles={rectangles}
        handleOnBlur={() => {
          setIsWritingText(false);
          localActions.setWritingText(text, layerUuid);
        }}
        handleTextChange={handleTextChange}
        zIndex={zIndex}
        onSelected={onSelected}
      />
    </div>
  );
};

function Drawing({
  lines, handleOnBlur, handleOnFocus, zIndex, onSelected, circles, text, handleTextChange, ellipses, rectangles,
}) {
  const globalState = {
    currentElementGroup: useSelector((state) => state.layers.currentElementGroup),
    elementGroups: useSelector((state) => state.layers.elementGroups),
  };

  const getAllElements = () => {
    const allElements = [...lines, ...circles, ...ellipses, ...rectangles, ...text].map((el) => {
      if (el.constructor === Array) {
        const index = globalState.elementGroups.findIndex((eg) => eg.uuid === el[0].elementGroup.uuid);
        return {
          order: Math.abs(index - globalState.elementGroups.length),
          line: el,
        };
      }
      const index = globalState.elementGroups.findIndex((eg) => eg.uuid === el.elementGroup.uuid);
      return {
        order: Math.abs(index - globalState.elementGroups.length),
        ...el,
      };
    });
    return allElements.sort((f, s) => f.order - s.order);
  };

  return (
    <svg className="drawing" style={{ zIndex: zIndex + 1 }}>
      {
        getAllElements().map((el, index) => {
          if (el.line) {
            const eg = globalState.elementGroups.filter((e) => e.uuid === el.line[0].elementGroup.uuid);
            if (!eg.length || eg[0].isShown === false) return null;
            return (
              <DrawingLine
                key={index}
                line={el.line}
                onSelected={onSelected}
              />
            );
          }
          if (el.circleUuid) {
            const eg = globalState.elementGroups.filter((e) => e.uuid === el.elementGroup.uuid);
            if (!eg.length || eg[0].isShown === false) return null;
            return (
              <DrawingCircle
                key={index}
                circle={el}
                onSelected={onSelected}
              />
            );
          }
          if (el.ellipseUuid) {
            const eg = globalState.elementGroups.filter((e) => e.uuid === el.elementGroup.uuid);
            if (!eg.length || eg[0].isShown === false) return null;
            return (
              <DrawingEllipse
                key={index}
                ellipse={el}
                onSelected={onSelected}
              />
            );
          }

          if (el.rectangleUuid) {
            const eg = globalState.elementGroups.filter((e) => e.uuid === el.elementGroup.uuid);
            if (!eg.length || eg[0].isShown === false) return null;
            return (
              <DrawingRectangle
                key={index}
                rectangle={el}
                onSelected={onSelected}
              />
            );
          }

          if (el.textUuid) {
            const eg = globalState.elementGroups.filter((e) => e.uuid === el.elementGroup.uuid);
            if (!eg.length || eg[0].isShown === false) return null;
            return (
              <WritingText
                key={index}
                text={el}
                handleOnBlur={handleOnBlur}
                handleOnFocus={handleOnFocus}
                handleTextChange={handleTextChange}
                onSelected={onSelected}
              />
            );
          }
        })
      }
    </svg>
  );
}

const DrawingLine = ({
  line, onSelected,
}) => {
  const firstPoint = line[0];
  const {
    brushColor, brushSize, drawStyle, isDashedLine,
  } = firstPoint;

  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
  };

  if (drawStyle === DRAW_STYLE.FREEFORM) {
    const pathData = `M ${
      line
        .map((p) => `${p.x} ${p.y}`)
        .join(' L ')}`;

    return (
      <path
        className={
          (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === firstPoint.lineUuid.toString())
            ? 'path active' : 'path'
        }
        onClick={() => {
          onSelected(firstPoint.lineUuid);
        }}
        d={pathData}
        style={{ stroke: brushColor, strokeWidth: brushSize }}
      />
    );
  }

  if (drawStyle === DRAW_STYLE.STRAIGHT_LINES || drawStyle === DRAW_STYLE.DASHED_LINES) {
    const lastPoint = line[line.length - 1];
    return (
      <line
        x1={firstPoint.x}
        y1={firstPoint.y}
        x2={lastPoint.x}
        y2={lastPoint.y}
        stroke={brushColor}
        strokeWidth={brushSize}
        strokeDasharray={isDashedLine ? DEFAULT_STROKE_DASH_LINE : undefined}
        className={
          (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === firstPoint.lineUuid.toString())
            ? 'line active' : 'line'
        }
        onClick={() => {
          onSelected(firstPoint.lineUuid);
        }}
      />
    );
  }

  const points = `${
    line
      .map((p) => `${p.x},${p.y}`)
      .join(' ')}`;

  return (
    <g onClick={() => {
      onSelected(firstPoint.lineUuid);
    }}
    >
      <polyline
        points={points}
        strokeDasharray={isDashedLine ? DEFAULT_STROKE_DASH_LINE : undefined}
        style={{ stroke: brushColor, strokeWidth: brushSize }}
        className={
          (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === firstPoint.lineUuid.toString())
            ? 'polyline active' : 'polyline'
        }
        onClick={() => {
          onSelected(firstPoint.lineUuid);
        }}
      />
    </g>

  );
};

const WritingText = ({
  text, handleOnBlur, handleTextChange, onSelected,
}) => {
  const ref = useRef(null);
  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
    drawStyle: useSelector((state) => state.drawing.drawStyle),
  };

  return (
    <foreignObject x={text.x} y={text.y} width={text.width} height={text.height}>
      <div
        style={{ width: `${text.width}`, height: `${text.height}` }}
        onClick={() => onSelected(text.textUuid)}
      >
        <input
          ref={ref}
          onBlur={() => {
            handleOnBlur();
          }}
          style={
            {
              backgroundColor: 'transparent',
              width: `${text.width}`,
              height: `${text.height}`,
              color: `${text.color}`,
            }
          }
          onChange={(e) => {
            handleTextChange(e.target.value, text.textUuid);
          }}
          disabled={globalState.drawStyle === DRAW_STYLE.SELECT_ELEMENT}
          defaultValue={text.value}
          className={
            (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === text.textUuid.toString())
              ? 'text focused' : 'text'
          }
        />
      </div>
    </foreignObject>
  );
};

const DrawingCircle = ({
  circle, onSelected,
}) => {
  const {
    brushColor, brushSize, endPoint, x, y, circleUuid, isDashedLine,
  } = circle;

  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
  };

  const calculateRadius = () => {
    if (!x || !y || !endPoint.x || !endPoint.y) return 0;
    return Math.hypot(endPoint.x - x, endPoint.y - y);
  };

  const radius = calculateRadius();

  return (
    <circle
      cx={x}
      cy={y}
      r={radius}
      stroke={brushColor}
      strokeWidth={brushSize}
      strokeDasharray={isDashedLine ? DEFAULT_STROKE_DASH_LINE : undefined}
      className={
        (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === circleUuid.toString())
          ? 'line active' : 'line'
      }
      onClick={() => {
        onSelected(circleUuid);
      }}
    />
  );
};

const DrawingEllipse = ({
  ellipse, onSelected,
}) => {
  const {
    brushColor, brushSize, endPoint, x, y, ellipseUuid, isDashedLine,
  } = ellipse;

  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
  };

  return (
    <ellipse
      cx={x}
      cy={y}
      rx={Math.abs(endPoint.x - x)}
      ry={Math.abs(endPoint.y - y)}
      strokeDasharray={isDashedLine ? DEFAULT_STROKE_DASH_LINE : undefined}
      stroke={brushColor}
      fill="transparent"
      strokeWidth={brushSize}
      className={
        (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === ellipseUuid.toString())
          ? 'line active' : 'line'
      }
      onClick={() => {
        onSelected(ellipseUuid);
      }}
    />
  );
};

const DrawingRectangle = ({
  rectangle, onSelected,
}) => {
  const {
    brushColor, brushSize, endPoint, x, y, rectangleUuid, isDashedLine,
  } = rectangle;

  const globalState = {
    selectedElement: useSelector((state) => state.layers.selectedElement),
  };

  return (
    <rect
      x={x}
      y={y}
      width={Math.abs(endPoint.x - x)}
      height={Math.abs(endPoint.y - y)}
      strokeDasharray={isDashedLine ? DEFAULT_STROKE_DASH_LINE : undefined}
      stroke={brushColor}
      fill="transparent"
      strokeWidth={brushSize}
      className={
        (globalState.selectedElement.elementUuid && globalState.selectedElement.elementUuid.toString() === rectangleUuid.toString())
          ? 'line active' : 'line'
      }
      onClick={() => {
        onSelected(rectangleUuid);
      }}
    />
  );
};

export default DrawingLayer;
