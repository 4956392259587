import axios from 'axios';

export default {
  async post(path, param) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    return axios.post(process.env.REACT_APP_API + path, param, { headers });
  },
  async put(path, param) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    return axios.put(process.env.REACT_APP_API + path, param, { headers });
  },
  async get(path) {
    return axios.get(process.env.REACT_APP_API + path);
  },

  async delete(path) {
    return axios.delete(process.env.REACT_APP_API + path);
  },
  async postFormData(path, param) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };

    const formData = new FormData();
    formData.append('image', param.image);

    return axios.post(process.env.REACT_APP_API + path, formData, { headers });
  },
};
