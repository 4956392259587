/* eslint-disable no-plusplus */
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_SVG_ICON_SIZE, DRAW_STYLE } from '../lib/constants';
import { getPointsForStraightLines } from '../lib/utils';
import * as types from './actionTypes';

export function setBrushColor(brushColor) {
  return async (dispatch) => {
    dispatch({ type: types.SET_BRUSH_COLOR, brushColor });
  };
}

export function setBrushSize(brushSize) {
  return async (dispatch) => {
    dispatch({ type: types.SET_BRUSH_SIZE, brushSize });
  };
}

export function setDrawStyle(drawStyle) {
  return async (dispatch) => {
    dispatch({ type: types.SET_DRAW_STYLE, drawStyle });
  };
}

export function SetDrawingPolylines(drawingPolylines, uuid) {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          drawingPolylines,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setShowDrawingToolbar(showDrawingToolbar) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SHOW_DRAWING_TOOLBAR, showDrawingToolbar });
  };
}

export function setShowSelectedObjectToolbar(showSelectedObjectTools) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SHOW_SELECT_OBJECT_TOOLBAR, showSelectedObjectTools });
    if (!showSelectedObjectTools) {
      dispatch({
        type:
        types.SET_SELECTED_ELEMENT_UUID,
        selectedElement: { elementUuid: null, layerUuid: null },
      });
    }
  };
}

export function deleteIconsFromLine() {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) return;

    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const newIcons = layer.draggedIcons.filter(
          (icon) => icon.lineUuid !== selectedElement.elementUuid,
        );

        return {
          ...layer,
          draggedIcons: newIcons,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function addIconsToLine(Icon, selectedElement, lineType, layerUuid, distance, size) {
  return async (dispatch, getState) => {
    if (!lineType || !layerUuid || !Icon || !selectedElement) {
      return;
    }

    const state = await getState();

    const { projectLayers, selectedElement: element, currentElementGroup } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid.toString() === layerUuid.toString()) {
        const layerElement = document.getElementById(`layer_div_${layerUuid.toString()}`);

        if (!layerElement) return layer;

        const targetRect = layerElement.getBoundingClientRect();
        let newIcons = [];
        if (lineType === DRAW_STYLE.POLYLINES) {
          for (let i = 0; i < selectedElement.length; i++) {
            if (i !== selectedElement.length - 1) {
              const firstPoint = selectedElement[i];
              const lastPoint = selectedElement[i + 1];
              const skippFirst = i !== 0;
              const newPoints = getPointsForStraightLines(
                firstPoint.x, firstPoint.y, lastPoint.x, lastPoint.y, distance, skippFirst,
              ).map((point) => ({
                Icon,
                iconUuid: uuidv4(),
                lineUuid: element.elementUuid,
                elementGroup: currentElementGroup,
                rect: {
                  left: point.x - (DEFAULT_SVG_ICON_SIZE / 2),
                  top: point.y - (DEFAULT_SVG_ICON_SIZE / 2),
                  leftPercentage:
                  Math.ceil(((point.x - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.width) * 100),
                  topPercentage:
                  Math.ceil(((point.y - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.height) * 100),
                  size: Number(size),
                },
              }));

              newIcons = [...newIcons, ...newPoints];
            }
          }
        } else if (lineType === DRAW_STYLE.STRAIGHT_LINES || lineType === DRAW_STYLE.DASHED_LINES) {
          const firstPoint = selectedElement[0];
          const lastPoint = selectedElement[selectedElement.length - 1];

          newIcons = getPointsForStraightLines(
            firstPoint.x, firstPoint.y, lastPoint.x, lastPoint.y, distance,
          )
            .map((point) => ({
              Icon,
              iconUuid: uuidv4(),
              lineUuid: element.elementUuid,
              elementGroup: currentElementGroup,
              rect: {
                left: point.x - (DEFAULT_SVG_ICON_SIZE / 2),
                top: point.y - (DEFAULT_SVG_ICON_SIZE / 2),
                leftPercentage:
                Math.ceil(((point.x - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.width) * 100),
                topPercentage:
                Math.ceil(((point.y - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.height) * 100),
                size: Number(size),
              },
            }));
        } else if (lineType === DRAW_STYLE.FREEFORM) {
          let lastPoint = selectedElement[0];
          newIcons = [];
          selectedElement.map((point, index) => {
            const distanceFromLastPoint = Math.round(Math.hypot(
              point.x - lastPoint.x,
              point.y - lastPoint.y,
            ) * 100) / 100;
            if (index === 0 || distanceFromLastPoint >= distance) {
              newIcons.push({
                Icon,
                iconUuid: uuidv4(),
                lineUuid: element.elementUuid,
                elementGroup: currentElementGroup,
                rect: {
                  left: point.x - (DEFAULT_SVG_ICON_SIZE / 2),
                  top: point.y - (DEFAULT_SVG_ICON_SIZE / 2),
                  leftPercentage:
                Math.ceil(((point.x - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.width) * 100),
                  topPercentage:
                Math.ceil(((point.y - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.height) * 100),
                  size: Number(size),
                },
              });
              lastPoint = point;
            }
            return point;
          });
        } else if (lineType === DRAW_STYLE.CIRCLE) {
          const radius = Math.hypot(
            selectedElement.endPoint.x - selectedElement.x,
            selectedElement.endPoint.y - selectedElement.y,
          );
          newIcons = [
            { y: selectedElement.y - radius, x: selectedElement.x },
            { y: selectedElement.y + radius, x: selectedElement.x },
            { x: selectedElement.x - radius, y: selectedElement.y },
            { x: selectedElement.x + radius, y: selectedElement.y },
          ].map((point) => ({
            Icon,
            iconUuid: uuidv4(),
            lineUuid: element.elementUuid,
            elementGroup: currentElementGroup,
            rect: {
              left: point.x - (DEFAULT_SVG_ICON_SIZE / 2),
              top: point.y - (DEFAULT_SVG_ICON_SIZE / 2),
              leftPercentage:
              Math.ceil(((point.x - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.width) * 100),
              topPercentage:
              Math.ceil(((point.y - (DEFAULT_SVG_ICON_SIZE / 2)) / targetRect.height) * 100),
              size: Number(size),
            },
          }));
        }

        const newLayer = {
          ...layer,
          draggedIcons: [...layer.draggedIcons, ...newIcons],
        };
        return newLayer;
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDashedLine() {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;

    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const drawingLines = layer.drawingLines.map((dl) => {
          if (dl[0].lineUuid === selectedElement.elementUuid) {
            const newDl = dl.map((p) => ({ ...p, isDashedLine: !p.isDashedLine }));
            return newDl;
          }
          return dl;
        });
        const drawingRectangles = layer.drawingRectangles.map((dr) => {
          if (dr.rectangleUuid === selectedElement.elementUuid) {
            const newDr = { ...dr, isDashedLine: !dr.isDashedLine };
            return newDr;
          }
          return dr;
        });
        const drawingEllipses = layer.drawingEllipses.map((dr) => {
          if (dr.ellipseUuid === selectedElement.elementUuid) {
            const newDr = { ...dr, isDashedLine: !dr.isDashedLine };
            return newDr;
          }
          return dr;
        });
        const drawingCircles = layer.drawingCircles.map((dr) => {
          if (dr.circleUuid === selectedElement.elementUuid) {
            const newDr = { ...dr, isDashedLine: !dr.isDashedLine };
            return newDr;
          }
          return dr;
        });
        return {
          ...layer,
          drawingLines,
          drawingRectangles,
          drawingEllipses,
          drawingCircles,
        };
      }

      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function checkIfLineHasIcons() {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;

    let exists = false;

    projectLayers.map((layer) => {
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const newIcons = layer.draggedIcons.filter(
          (icon) => icon.lineUuid === selectedElement.elementUuid,
        );

        if (newIcons.length) exists = true;
      }
      return layer;
    });

    return exists;
  };
}
