import './ImageLayer.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import {
  IMAGE_LAYER_Z_INDEX,
} from '../../../lib/constants';

const ImageLayer = () => {
  const globalState = {
    imageUrl: useSelector((state) => state.image.imageUrl),
    imageData: useSelector((state) => state.image.imageData),
    zoomAction: useSelector((state) => state.image.zoomAction),
    canvasSize: useSelector((state) => state.layers.canvasSize),
  };

  return (
    <div
      style={{
        zIndex: IMAGE_LAYER_Z_INDEX,
      }}
      className="image-layer"
      id="bc-img"
    >
      <div
        style={{
          width: `${globalState.canvasSize.width}px`,
          height: `${globalState.canvasSize.height}px`,
        }}
        className="canvas-image-container"
        id="canvas-container"
      >
        {globalState.imageUrl && typeof globalState.imageData.name === 'string'
        && (

          <img src={globalState.imageUrl} alt={globalState.imageData.name} />
        )}

      </div>
    </div>
  );
};

export default ImageLayer;
