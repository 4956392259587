/* eslint-disable no-unused-expressions */
import axios from 'axios';

import api from '../api';
import { GetProjects, SaveProject } from '../endpoints';
import { ICONS_IN_PAGE } from '../lib/constants';

const ProjectService = {
  GetProjects: async () => {
    const response = await api.get(GetProjects);
    return response.data;
  },
  SaveProject: async (params) => {
    const response = await api.post(SaveProject, params);
    return response.data;
  },
  GetProjectById: async (id) => {
    const response = await api.get(`${GetProjects}/${id}`);
    return response.data;
  },
  UpdateProjectById: async (id, params) => {
    const response = await api.put(`${GetProjects}/${id}`, params);
    return response.data;
  },
  DeleteProjectById: async (id) => {
    await api.delete(`${GetProjects}/${id}`);
  },
  UploadBackgroundImage: async (id, params) => {
    await api.postFormData(`${GetProjects}/${id}/image`, params);
  },
  GetIconCategories: async (user) => {
    const response = await axios.get('http://api.portal.roads.dk/api/v1/SignCategories?take=1000', {
      headers: {
        Authorization: `Bearer ${user.Token}`,
      },
    });
    return response.data;
  },
  GetFilteredIcons: async (user, name, category, isFavorite, page) => {
    const currentPage = page || 0;
    const body = [];
    isFavorite
      ? body.push({
        Property: 'VisibleInOrders',
        Value: 'true',
      })
      : body.push({
        Property: 'VisibleInOrders',
        Value: 'false',
      });

    if (name) {
      body.push({
        Property: 'Name',
        Value: name,
      });
    }

    if (category && category.Id) {
      body.push({
        Property: 'CategoryId',
        Value: category.Id,
      });
    }
    const response = await axios.post(`http://api.portal.roads.dk/api/v1/Signs/search?column=name&ordering=asc&take=20&skip=${currentPage * ICONS_IN_PAGE}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${user.Token}`,
        },
      });

    return response.data;
  },
};

export default ProjectService;
