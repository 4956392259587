import { BiTrafficCone } from 'react-icons/bi';
import { BsCone, BsConeStriped } from 'react-icons/bs';
import {
  GiHazardSign, GiStopSign, GiTrafficCone, GiTrafficLightsReadyToGo, GiWoodenSign,
} from 'react-icons/gi';

export const DROPPED_ITEM_Z_INDEX = 3;
export const DRAWING_LINES_Z_INDEX = 3;
export const IMAGE_LAYER_Z_INDEX = 0;
export const FOREGROUND_LAYER_Z_INDEX = 2;
export const BACKGROUND_LAYER_Z_INDEX = 1;
export const FLOATING_TOOLBARS_OFFSET = 15;
export const HEADER_OFFSET = 14;
export const DEFAULT_SVG_ICON_SIZE = 64;
export const DEFAULT_STROKE_DASH_LINE = 20;
export const ICONS_IN_PAGE = 20;

export const LAYERS = {
  IMAGE_LAYER: 'imageLayer',
  DND_LAYER: 'dndLayer',
  DRAWING_LAYER: 'drawingLayer',
};
export const DRAW_STYLE = {
  FREEFORM: 'freeform',
  STRAIGHT_LINES: 'straightLines',
  WRITE_TEXT: 'writeText',
  POLYLINES: 'polylines',
  SELECT_ELEMENT: 'selectElement',
  CIRCLE: 'circle',
  DASHED_LINES: 'dashedLines',
  ELLIPSE: 'ellipse',
  RECTANGLE: 'rectangle',
};

export const ZOOM_ACTIONS = {
  ZOOM_IN: 'zoomIn',
  ZOOM_OUT: 'zoomOut',
  ZOOM_CENTER: 'zoomCenter',
  ZOOM_RESET: 'zoomReset',
};

export const DRAGGABLE_ICONS = {
  trafficCones: [GiTrafficCone, BsCone, BsConeStriped, BiTrafficCone],
  trafficSignes: [GiTrafficLightsReadyToGo, GiStopSign, GiHazardSign, GiWoodenSign],
};
