import './translations/i18n';
import './index.scss';

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import Routes from './routes';

ReactDOM.render(
  <StrictMode>
    <HashRouter>
      <Routes />
    </HashRouter>
  </StrictMode>,
  document.getElementById('root'),
);
