/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import { v4 as uuidv4 } from 'uuid';

import * as types from './actionTypes';

export function setForegroundLayer(foregroundLayer) {
  return async (dispatch) => {
    dispatch({ type: types.SET_FOREGROUND_LAYER, foregroundLayer });
  };
}

export function setShowLayersToolbar(showLayersToolbar) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SHOW_LAYERS_TOOLBAR, showLayersToolbar });
  };
}

export function setOpenDeleteElementModal(openDeleteElementModal) {
  return async (dispatch) => {
    dispatch({ type: types.OPEN_DELETE_ELEMENT_MODAL, openDeleteElementModal });
  };
}

export function addNewLayer(layer) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_NEW_LAYER, layer });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function changeElementProperty(selectedElement, property, value) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const drawingLines = layer.drawingLines.map((dl) => {
          if (dl[0].lineUuid === selectedElement.elementUuid) {
            const newDl = dl.map((p) => {
              const np = { ...p };
              np[property] = value;
              return np;
            });
            return newDl;
          }
          return dl;
        });
        const drawingRectangles = layer.drawingRectangles.map((dr) => {
          if (dr.rectangleUuid === selectedElement.elementUuid) {
            const newDr = { ...dr };
            newDr[property] = value;
            return newDr;
          }
          return dr;
        });
        const drawingEllipses = layer.drawingEllipses.map((dr) => {
          if (dr.ellipseUuid === selectedElement.elementUuid) {
            const newDr = { ...dr };
            newDr[property] = value;
            return newDr;
          }
          return dr;
        });
        const drawingCircles = layer.drawingCircles.map((dr) => {
          if (dr.circleUuid === selectedElement.elementUuid) {
            const newDr = { ...dr };
            newDr[property] = value;
            return newDr;
          }
          return dr;
        });
        return {
          ...layer,
          drawingLines,
          drawingRectangles,
          drawingEllipses,
          drawingCircles,
        };
      }

      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_UPDATE_SELECTED_ELEMENT, updateSelectedElement: true });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDraggedIcons(draggedIcons, uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          draggedIcons,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function changeIconRotation(rotateAngle, iconUuid) {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;
    const newLayers = [...projectLayers];

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const { draggedIcons } = layer;

        const newDraggedIcons = draggedIcons.map((icon) => {
          if (icon.iconUuid.toString() === selectedElement.elementUuid.toString()) {
            return {
              ...icon,
              rect: {
                ...icon.rect,
                rotateAngle: Number(rotateAngle),
              },
            };
          }
          return icon;
        });

        layer.draggedIcons = newDraggedIcons;

        break;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_UPDATE_SELECTED_ELEMENT, updateSelectedElement: true });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function changeIconSizeAndMove(size, iconUuid, top, left) {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;
    const newLayers = [...projectLayers];

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const { draggedIcons } = layer;

        const newDraggedIcons = draggedIcons.map((icon) => {
          if (icon.iconUuid.toString() === selectedElement.elementUuid.toString()) {
            return {
              ...icon,
              rect: {
                ...icon.rect,
                size: Number(size),
                top: Number(top),
                left: Number(left),
              },
            };
          }
          return icon;
        });

        layer.draggedIcons = newDraggedIcons;

        break;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_UPDATE_SELECTED_ELEMENT, updateSelectedElement: true });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDrawingLines(drawingLines, uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          drawingLines,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDrawingCircles(drawingCircles, uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          drawingCircles,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDrawingEllipses(drawingEllipses, uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          drawingEllipses,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setDrawingRectangles(drawingRectangles, uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          drawingRectangles,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setWritingText(layerText, uuid, selectedUuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { projectLayers } = state.layers;
    const newLayers = projectLayers.map((layer) => {
      if (layer.uuid === uuid) {
        return {
          ...layer,
          layerText,
        };
      }
      return layer;
    });

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    if (selectedUuid) {
      dispatch({
        type: types.SET_SELECTED_ELEMENT_UUID, selectedElement: { elementUuid: selectedUuid, uuid },
      });

      dispatch({ type: types.SET_SHOW_SELECT_OBJECT_TOOLBAR, showSelectedObjectTools: true });
    }
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setCurrentElementGroup(elementGroup) {
  return async (dispatch) => {
    dispatch({ type: types.SET_CURRENT_ELEMENT_GROUP, elementGroup });
  };
}

export function addNewElementGroup() {
  return async (dispatch, getState) => {
    const state = getState();

    const { elementGroups } = state.layers;
    const newElementGroups = [...elementGroups, {
      uuid: uuidv4(),
      name: 'New Layer',
      isShown: true,
    }];
    dispatch({ type: types.SET_ELEMENT_GROUPS, elementGroups: newElementGroups });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function removeElementGroup(uuid) {
  return async (dispatch, getState) => {
    const state = getState();

    const { elementGroups, projectLayers } = state.layers;
    const newElementGroups = elementGroups.filter((eg) => eg.uuid !== uuid);

    const layer = projectLayers[0];

    layer.draggedIcons = layer.draggedIcons.filter((icon) => icon.elementGroup.uuid !== uuid);
    layer.drawingLines = layer.drawingLines.filter((line) => line[0].elementGroup.uuid !== uuid);

    layer.drawingCircles = layer.drawingCircles.filter((circle) => circle.elementGroup.uuid !== uuid);
    layer.drawingEllipses = layer.drawingEllipses.filter((elipse) => elipse.elementGroup.uuid !== uuid);

    layer.drawingRectangles = layer.drawingRectangles.filter((rect) => rect.elementGroup.uuid !== uuid);
    layer.layerText = layer.layerText.filter((text) => text.elementGroup.uuid !== uuid);

    const newLayers = [layer];

    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });

    dispatch({ type: types.SET_ELEMENT_GROUPS, elementGroups: newElementGroups });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setElementGroups(elementGroups) {
  return async (dispatch) => {
    dispatch({ type: types.SET_ELEMENT_GROUPS, elementGroups });
  };
}

export function updateLayersOrder(projectLayers) {
  return async (dispatch) => {
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setSelectedEleementUuid(elementUuid, layerUuid) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SELECTED_ELEMENT_UUID, selectedElement: { elementUuid, layerUuid },
    });

    dispatch({ type: types.SET_SHOW_SELECT_OBJECT_TOOLBAR, showSelectedObjectTools: true });
  };
}

export function setUpdateSelectedElement(updateSelectedElement) {
  return async (dispatch) => {
    dispatch({ type: types.SET_UPDATE_SELECTED_ELEMENT, updateSelectedElement });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function moveElement(startPoint, endPoint) {
  return async (dispatch, getState) => {
    const state = await getState();
    const { projectLayers, selectedElement } = state.layers;
    const { zoomScale } = state.image;

    const newLayers = [...projectLayers];

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const {
          drawingLines, drawingCircles, draggedIcons, drawingEllipses, drawingRectangles, layerText,
        } = layer;

        const newLayerText = layerText.map((text) => {
          if (text.textUuid.toString() === selectedElement.elementUuid.toString()) {
            const newText = {
              ...text,
              x: text.x + (endPoint.x - startPoint.x) / zoomScale,
              y: text.y + (endPoint.y - startPoint.y) / zoomScale,
            };
            return newText;
          }
          return text;
        });

        const newDrawingLines = drawingLines.map((line) => {
          if (line[0].lineUuid.toString() === selectedElement.elementUuid.toString()) {
            const newLine = line.map((point) => ({
              ...point,
              x: point.x + (endPoint.x - startPoint.x) / zoomScale,
              y: point.y + (endPoint.y - startPoint.y) / zoomScale,
            }));
            return newLine;
          }
          return line;
        });

        const newDrawingCircles = drawingCircles.map((circle) => {
          if (circle.circleUuid.toString() === selectedElement.elementUuid.toString()) {
            const newCircle = {
              ...circle,
              x: circle.x + (endPoint.x - startPoint.x) / zoomScale,
              y: circle.y + (endPoint.y - startPoint.y) / zoomScale,
              endPoint: {
                ...circle.endPoint,
                x: circle.endPoint.x + (endPoint.x - startPoint.x) / zoomScale,
                y: circle.endPoint.y + (endPoint.y - startPoint.y) / zoomScale,
              },
            };
            return newCircle;
          }
          return circle;
        });

        const newDrawingEllipses = drawingEllipses.map((ellipse) => {
          if (ellipse.ellipseUuid.toString() === selectedElement.elementUuid.toString()) {
            const newEllipse = {
              ...ellipse,
              x: ellipse.x + (endPoint.x - startPoint.x) / zoomScale,
              y: ellipse.y + (endPoint.y - startPoint.y) / zoomScale,
              endPoint: {
                ...ellipse.endPoint,
                x: ellipse.endPoint.x + (endPoint.x - startPoint.x) / zoomScale,
                y: ellipse.endPoint.y + (endPoint.y - startPoint.y) / zoomScale,
              },
            };
            return newEllipse;
          }
          return ellipse;
        });

        const newDrawingRectangles = drawingRectangles.map((rectangle) => {
          if (rectangle.rectangleUuid.toString() === selectedElement.elementUuid.toString()) {
            const newRectangle = {
              ...rectangle,
              x: rectangle.x + (endPoint.x - startPoint.x) / zoomScale,
              y: rectangle.y + (endPoint.y - startPoint.y) / zoomScale,
              endPoint: {
                ...rectangle.endPoint,
                x: rectangle.endPoint.x + (endPoint.x - startPoint.x) / zoomScale,
                y: rectangle.endPoint.y + (endPoint.y - startPoint.y) / zoomScale,
              },
            };
            return newRectangle;
          }
          return rectangle;
        });

        const newDraggedIcons = draggedIcons
          .map((icon) => {
            if (icon.lineUuid && icon.lineUuid.toString() === selectedElement.elementUuid.toString()) {
              const layerElement = document.getElementById(`layer_div_${layer.uuid.toString()}`);
              const targetRect = layerElement.getBoundingClientRect();
              return {
                ...icon,
                rect: {
                  ...icon.rect,
                  left: icon.rect.left + (endPoint.x - startPoint.x),
                  top: icon.rect.top + (endPoint.y - startPoint.y),
                  leftPercentage:
                  Math.ceil(((icon.rect.left + (endPoint.x - startPoint.x)) / targetRect.width) * 100),
                  topPercentage:
                  Math.ceil(((icon.rect.top + (endPoint.y - startPoint.y)) / targetRect.height) * 100),
                },
              };
            }
            return icon;
          });

        layer.drawingLines = newDrawingLines;
        layer.drawingCircles = newDrawingCircles;
        layer.draggedIcons = newDraggedIcons;
        layer.drawingEllipses = newDrawingEllipses;
        layer.drawingRectangles = newDrawingRectangles;
        layer.layerText = newLayerText;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_UPDATE_SELECTED_ELEMENT, updateSelectedElement: true });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function changeIconSize(size) {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;
    const newLayers = [...projectLayers];

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const { draggedIcons } = layer;

        const newDraggedIcons = draggedIcons.map((icon) => {
          if (icon.iconUuid.toString() === selectedElement.elementUuid.toString()) {
            return {
              ...icon,
              rect: {
                ...icon.rect,
                size: Number(size),
              },
            };
          }
          return icon;
        });

        layer.draggedIcons = newDraggedIcons;

        break;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function updateText(text) {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;
    const newLayers = [...projectLayers];
    if (!selectedElement.layerUuid || !selectedElement.elementUuid || !text) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const { layerText } = layer;

        const newLayerText = layerText.map((t) => {
          if (t.textUuid && text.textUuid && t.textUuid.toString() === text.textUuid.toString()) {
            return text;
          }
          return t;
        });

        layer.layerText = newLayerText;

        break;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function removeElementFromLayer() {
  return async (dispatch, getState) => {
    const state = await getState();

    const { projectLayers, selectedElement } = state.layers;
    const newLayers = [...projectLayers];

    if (!selectedElement.layerUuid || !selectedElement.elementUuid) {
      return;
    }

    for (let i = 0; i < newLayers.length; i++) {
      const layer = newLayers[i];
      if (layer.uuid.toString() === selectedElement.layerUuid.toString()) {
        const {
          draggedIcons, drawingLines, drawingCircles, drawingEllipses, drawingRectangles,
        } = layer;

        const newDraggedIcons = draggedIcons.filter(
          (icon) => icon.iconUuid.toString() !== selectedElement.elementUuid.toString(),
        );

        let deletingElementIndex = null;

        drawingLines.map((line, index) => {
          const firstPoint = line[0];
          const { lineUuid } = firstPoint;
          if (lineUuid.toString() === selectedElement.elementUuid.toString()) {
            deletingElementIndex = index;
          }
          return line;
        });

        const newDrawingLines = drawingLines.filter(
          (line, index) => index !== deletingElementIndex,
        );

        const newDrawingCircles = drawingCircles.filter(
          (circle) => circle.circleUuid.toString() !== selectedElement.elementUuid.toString(),
        );

        const newDrawingEllipses = drawingEllipses.filter(
          (ellipse) => ellipse.ellipseUuid.toString() !== selectedElement.elementUuid.toString(),
        );

        const newDrawingRectangles = drawingRectangles.filter(
          (rectangle) => rectangle.rectangleUuid.toString() !== selectedElement.elementUuid.toString(),
        );

        layer.draggedIcons = newDraggedIcons;
        layer.drawingLines = newDrawingLines;
        layer.drawingCircles = newDrawingCircles;
        layer.drawingEllipses = newDrawingEllipses;
        layer.drawingRectangles = newDrawingRectangles;

        break;
      }
    }
    dispatch({ type: types.SET_ALL_LAYERS, projectLayers: newLayers });

    dispatch({
      type: types.SET_SELECTED_ELEMENT_UUID,
      selectedElement: { elementUuid: null, layerUuid: null },
    });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setCanvasSize(canvasSize) {
  return async (dispatch) => {
    dispatch({ type: types.SET_CANVAS_SIZE, canvasSize });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}
