import { combineReducers } from 'redux';

import * as types from '../actions/actionTypes';

const imageUrl = (state = '', action) => {
  switch (action.type) {
    case types.SET_IMAGE_URL:
      return action.imageUrl;
    default:
      return state;
  }
};

const imageData = (state = {}, action) => {
  switch (action.type) {
    case types.SET_IMAGE_DATA:
      return action.imageData;
    default:
      return state;
  }
};

const zoomAction = (state = '', action) => {
  switch (action.type) {
    case types.SET_ZOOM_ACTION:
      return action.zoomAction;
    default:
      return state;
  }
};

const zoomScale = (state = 1, action) => {
  switch (action.type) {
    case types.SET_ZOOM_SCALE:
      return action.scale;
    default:
      return state;
  }
};

export default combineReducers({
  imageUrl,
  imageData,
  zoomAction,
  zoomScale,
});
