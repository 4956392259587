import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import {
  HashRouter, Route, Switch,
} from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';

import EditorPage from './pages/EditorPage/EditorPage';
import LoginPage from './pages/LoginPage/LoginPage';
import rootReducer from './reducers';

const preloadedState = undefined;

const store = createStore(
  rootReducer,
  preloadedState,
  composeWithDevTools(applyMiddleware(ReduxThunk)),
);

const Routes = () => (
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <DndProvider backend={HTML5Backend}>
          <CookiesProvider>
            <Route path="/" exact component={EditorPage} />
            <Route path="/login" exact component={LoginPage} />
          </CookiesProvider>
        </DndProvider>
      </Switch>
    </HashRouter>
  </Provider>
);

export default Routes;
