export const SET_IMAGE_DATA = 'SET_IMAGE_DATA';
export const SET_IMAGE_URL = 'SET_IMAGE_URL';
export const SET_FOREGROUND_LAYER = 'SET_FOREGROUND_LAYER';
export const SET_BRUSH_COLOR = 'SET_BRUSH_COLOR';
export const SET_BRUSH_SIZE = 'SET_BRUSH_SIZE';
export const SET_DRAW_STYLE = 'SET_DRAW_STYLE';
export const SET_ALL_LAYERS = 'SET_ALL_LAYERS';
export const SET_CURRENT_ELEMENT_GROUP = 'SET_CURRENT_ELEMENT_GROUP';
export const SET_SHOW_LAYERS_TOOLBAR = 'SET_SHOW_LAYERS_TOOLBAR';
export const SET_SHOW_DRAWING_TOOLBAR = 'SET_SHOW_DRAWING_TOOLBAR';
export const SET_SHOW_SELECT_OBJECT_TOOLBAR = 'SET_SHOW_SELECT_OBJECT_TOOLBAR';
export const ADD_NEW_LAYER = 'ADD_NEW_LAYER';
export const ADD_NEW_ELEMENT_GROUP = 'ADD_NEW_ELEMENT_GROUP';
export const SET_ELEMENT_GROUPS = 'SET_ELEMENT_GROUPS';
export const SET_PROJECT_TITLE = 'SET_PROJECT_TITLE';
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS';
export const SET_ZOOM_ACTION = 'SET_ZOOM_ACTION';
export const SET_ZOOM_SCALE = 'SET_ZOOM_SCALE';
export const SET_SELECTED_ELEMENT_UUID = 'SET_SELECTED_ELEMENT_UUID';
export const SET_UPDATE_SELECTED_ELEMENT = 'SET_UPDATE_SELECTED_ELEMENT';
export const OPEN_DELETE_ELEMENT_MODAL = 'OPEN_DELETE_ELEMENT_MODAL';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_IS_NEW_PROJECT = 'SET_IS_NEW_PROJECT';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_IS_PROJECT_CHANGED = 'SET_IS_PROJECT_CHANGED';
export const SET_CANVAS_SIZE = 'SET_CANVAS_SIZE';
export const SET_ICON_CATEGORIES = 'SET_ICON_CATEGORIES';
export const SET_FILTERED_ICONS = 'SET_FILTERED_ICONS';
