/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';

const SaveChangesModal = ({
  closeModal, showModal, onSave, onCancel,
}) => (
  <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    show={showModal}
    onHide={() => closeModal()}
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        You have some unsaved changes in your project. If you leave before saving, your changes will be lost.
      </Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button
        variant="success"
        onClick={() => onSave()}
      >
        Save changes
      </Button>
      <Button variant="danger" onClick={() => onCancel()}>Ignore changes</Button>
    </Modal.Footer>
  </Modal>
);

export default SaveChangesModal;
