import { combineReducers } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import * as types from '../actions/actionTypes';
import { LAYERS } from '../lib/constants';

const INITIAL_ELEMENT_GROUP = {
  uuid: uuidv4(),
  name: 'Default Layer',
  isShown: true,
};

const foregroundLayer = (state = LAYERS.DND_LAYER, action) => {
  switch (action.type) {
    case types.SET_FOREGROUND_LAYER:
      return action.foregroundLayer;
    default:
      return state;
  }
};

const elementGroups = (state = [INITIAL_ELEMENT_GROUP], action) => {
  switch (action.type) {
    case types.SET_ELEMENT_GROUPS:
      return action.elementGroups;
    default:
      return state;
  }
};

const initialLayers = [{
  title: 'Default Layer',
  isShown: true,
  draggedIcons: [],
  drawingLines: [],
  drawingCircles: [],
  drawingEllipses: [],
  drawingRectangles: [],
  layerText: [],
  uuid: uuidv4(),
}];

const canvasSize = (state = { width: 1280, height: 720 }, action) => {
  switch (action.type) {
    case types.SET_CANVAS_SIZE:
      return action.canvasSize;
    default:
      return state;
  }
};

const projectLayers = (state = initialLayers, action) => {
  switch (action.type) {
    case types.SET_ALL_LAYERS:
      return action.projectLayers;
    case types.ADD_NEW_LAYER:
      return [action.layer, ...state];
    default:
      return state;
  }
};

const currentElementGroup = (state = INITIAL_ELEMENT_GROUP, action) => {
  switch (action.type) {
    case types.SET_CURRENT_ELEMENT_GROUP:
      return action.elementGroup;
    default:
      return state;
  }
};

const showLayersToolbar = (state = true, action) => {
  switch (action.type) {
    case types.SET_SHOW_LAYERS_TOOLBAR:
      return action.showLayersToolbar;
    default:
      return state;
  }
};

const selectedElement = (state = { elementUuid: null, layerUuid: null }, action) => {
  switch (action.type) {
    case types.SET_SELECTED_ELEMENT_UUID:
      return action.selectedElement;
    default:
      return state;
  }
};

const updateSelectedElement = (state = false, action) => {
  switch (action.type) {
    case types.SET_UPDATE_SELECTED_ELEMENT:
      return action.updateSelectedElement;
    default:
      return state;
  }
};

const openDeleteElementModal = (state = false, action) => {
  switch (action.type) {
    case types.OPEN_DELETE_ELEMENT_MODAL:
      return action.openDeleteElementModal;
    default:
      return state;
  }
};

export default combineReducers({
  foregroundLayer,
  projectLayers,
  showLayersToolbar,
  selectedElement,
  updateSelectedElement,
  openDeleteElementModal,
  currentElementGroup,
  elementGroups,
  canvasSize,
});
