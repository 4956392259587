import './LoginPage.scss';

import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as actions from '../../actions';

const LoginPage = () => {
  const { t } = useTranslation();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const localActions = {
    getUserToken: (user, pass, onSuccess, onFail) => dispatch(
      actions.user.getUserToken(user, pass, onSuccess, onFail),
    ),
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    localActions.getUserToken(
      username,
      password,
      (cookie) => {
        setCookie('currentUser', cookie);
        history.push('/');
      },
      () => {
        setValidated(false);
        removeCookie('currentUser');
      },
    );
  };

  useEffect(() => {
    if (cookies && cookies.currentUser && cookies.currentUser.Token) {
      history.push('/');
    }
  }, []);

  return (
    <Container className="login-page-container d-flex justify-content-center align-items-center">
      <div className="title">SKILTEPLANNER</div>
      <div className="panel-wrapper">
        <Form className="panel-body" validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="fw-bold">User</Form.Label>
            <Form.Control type="text" placeholder="User" value={username} onChange={(e) => setUserName(e.target.value)} required />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="fw-bold">{t('loginPage.password')}</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </Form.Group>
          <Button className="btn btn-submit form-control" type="submit">
            {t('loginPage.login')}
          </Button>
        </Form>
      </div>

    </Container>

  );
};

export default LoginPage;
