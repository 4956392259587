import { combineReducers } from 'redux';

import drawing from './drawing';
import image from './image';
import layers from './layers';
import project from './project';

const rootReducer = combineReducers({
  image,
  layers,
  drawing,
  project,
});

export default (state, action) => rootReducer(action.type === 'RESET_REDUCER' ? undefined : state, action);
