import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../actions';

const ChooseFileInput = ({ children }) => {
  const dispatch = useDispatch();

  const localActions = {
    setImageData: (data) => dispatch(actions.image.setImageData(data)),
    setImageUrl: (imageUrl) => dispatch(actions.image.setImageUrl(imageUrl)),
  };

  const globalState = {
    imageData: useSelector((state) => state.image.imageData),
    imageUrl: useSelector((state) => state.image.imageUrl),
  };

  useEffect(() => {
    if (typeof globalState.imageData.name === 'string') {
      localActions.setImageUrl(URL.createObjectURL(globalState.imageData));
    }
  }, [globalState.imageData]);

  return (
    <label htmlFor="chooseFile" style={{ cursor: 'pointer' }}>
      {children}
      <input
        type="file"
        id="chooseFile"
        onChange={(e) => localActions.setImageData(e.target.files[0])}
        style={{ display: 'none' }}
      />
    </label>
  );
};

ChooseFileInput.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChooseFileInput;
