/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';

import { ItemTypes } from '../../../lib/Items';

const DraggableIconFiltered = ({ icon }) => {
  const [iconUuid] = useState(uuidv4());
  const [, drag] = useDrag({
    type: ItemTypes.DRAGGABLE_ICON,
    item: {
      Icon: () => (
        <img src={icon.SignImageURL} alt="icon" style={{ width: '100%', height: '100%' }} />
      ),
      iconInfo: icon,
      iconUuid,
      isFiltered: true,
    },
  });

  return (
    <div className="single-icon-container" key={iconUuid} ref={drag}>
      <img style={{ width: '100%', height: '100px', padding: '2px' }} src={icon.SignImageURL} alt="icon" />
    </div>
  );
};

export default DraggableIconFiltered;
