import { combineReducers } from 'redux';

import * as types from '../actions/actionTypes';

const projectTitle = (state = `${'New Project'}-${new Date().toDateString()}`, action) => {
  switch (action.type) {
    case types.SET_PROJECT_TITLE:
      return action.projectTitle;
    default:
      return state;
  }
};

const allProjects = (state = [], action) => {
  switch (action.type) {
    case types.SET_ALL_PROJECTS:
      return action.allProjects.reverse();
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_LOADING:
      return action.isLoading;
    default:
      return state;
  }
};

const isNewProject = (state = true, action) => {
  switch (action.type) {
    case types.SET_IS_NEW_PROJECT:
      return action.isNewProject;
    default:
      return state;
  }
};

const projectId = (state = '', action) => {
  switch (action.type) {
    case types.SET_PROJECT_ID:
      return action.projectId;
    default:
      return state;
  }
};

const isProjectChanged = (state = false, action) => {
  switch (action.type) {
    case types.SET_IS_PROJECT_CHANGED:
      return action.isProjectChanged;
    default:
      return state;
  }
};

const iconCategories = (state = [], action) => {
  switch (action.type) {
    case types.SET_ICON_CATEGORIES:
      return action.iconCategories;
    default:
      return state;
  }
};

const filteredIcons = (state = { icons: [], iconsCount: 0 }, action) => {
  switch (action.type) {
    case types.SET_FILTERED_ICONS:
      return action.filteredIcons;
    default:
      return state;
  }
};

export default combineReducers({
  projectTitle,
  allProjects,
  isLoading,
  isNewProject,
  projectId,
  isProjectChanged,
  iconCategories,
  filteredIcons,
});
