import * as types from './actionTypes';

export function setImageUrl(imageUrl) {
  return async (dispatch) => {
    dispatch({ type: types.SET_IMAGE_URL, imageUrl });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setImageData(imageData) {
  return async (dispatch) => {
    dispatch({ type: types.SET_IMAGE_DATA, imageData });
    dispatch({ type: types.SET_IS_PROJECT_CHANGED, isProjectChanged: true });
  };
}

export function setZoomAction(zoomAction) {
  return async (dispatch) => {
    dispatch({ type: types.SET_DRAW_STYLE, drawStyle: '' });
    dispatch({ type: types.SET_ZOOM_ACTION, zoomAction });
  };
}

export function setZoomScale(scale) {
  return async (dispatch) => {
    dispatch({ type: types.SET_ZOOM_SCALE, scale });
  };
}
