/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './ToolBar.scss';

import React, { useEffect, useRef, useState } from 'react';
import {
  AiOutlineFolderOpen,
  AiOutlineSave, AiOutlineZoomIn, AiOutlineZoomOut,
} from 'react-icons/ai';
import {
  GrTrash,
} from 'react-icons/gr';
import {
  MdZoomOutMap,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import { ReactComponent as ZoomReset } from '../../../img/zoom-reset.svg';
import {
  ZOOM_ACTIONS,
} from '../../../lib/constants';

const ToolBar = () => {
  const [openColorPicker, setOpenColorPicler] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(1280);
  const [canvasHeight, setCanvasHeight] = useState(720);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const localActions = {
    setZoomAction:
        (zoomAction) => dispatch(actions.image.setZoomAction(zoomAction)),
    createNewProject:
        () => dispatch(actions.project.createNewProject()),
    saveProject:
        () => dispatch(actions.project.saveProject()),
    removeElementFromLayer: () => dispatch(actions.layers.removeElementFromLayer()),
    deleteIconsFromLine:
        () => dispatch(actions.drawing.deleteIconsFromLine()),
    setOpenDeleteElementModal:
        (openDeleteElementModal) => dispatch(actions.layers.setOpenDeleteElementModal(openDeleteElementModal)),
    setCanvasSize:
        (canvasSize) => dispatch(actions.layers.setCanvasSize(canvasSize)),
  };

  const globalState = {
    canvasSize: useSelector((state) => state.layers.canvasSize),
  };

  useEffect(() => {
    setCanvasWidth(globalState.canvasSize.width);
    setCanvasHeight(globalState.canvasSize.height);
  }, []);

  useEffect(() => {
    localActions.setCanvasSize({ height: canvasHeight, width: canvasWidth });
  }, [canvasHeight, canvasWidth]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (openColorPicker && ref.current && !ref.current.contains(e.target)) {
        setOpenColorPicler(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openColorPicker]);

  return (
    <div className="toolbar-container">
      <div
        className="action"
        title="New Project..."

      >
        <AiOutlineFolderOpen size={18} color="#444" onClick={() => localActions.createNewProject()} />
      </div>
      <div className="action" title="Save">
        <AiOutlineSave size={18} color="#444" onClick={() => localActions.saveProject()} />
      </div>

      <div className="action-divider" />

      <div className="action">
        <GrTrash
          size={18}
          color="#444"
          title="Delete Element"
          onClick={async () => {
            localActions.setOpenDeleteElementModal(true);
          }}
        />
      </div>

      <div className="action-divider" />

      <div className="action">
        <AiOutlineZoomIn
          size={18}
          color="#444"
          title="Zoom In"
          onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_IN)}
        />
      </div>
      <div className="action">
        <AiOutlineZoomOut
          size={18}
          color="#444"
          title="Zoom Out"
          onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_OUT)}
        />
      </div>
      <div className="action">
        <MdZoomOutMap
          size={18}
          color="#444"
          title="Center Image"
          onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_CENTER)}
        />
      </div>
      <div className="action">
        <ZoomReset
          size={18}
          color="#444"
          title="Reset Zoom"
          onClick={() => localActions.setZoomAction(ZOOM_ACTIONS.ZOOM_RESET)}
        />
      </div>
      <label htmlFor="canvas-width">
        Width:&nbsp;
        <input
          style={{ width: '70px', marginRight: '10px' }}
          type="number"
          id="canvas-width"
          value={canvasWidth}
          onChange={(e) => {
            if (Number(e.target.value) <= 0) return;
            setCanvasWidth(Number(e.target.value));
          }}
        />
      </label>
      <label htmlFor="canvas-height">
        Height:&nbsp;
        <input
          style={{ width: '70px', marginLeft: '10px' }}
          type="number"
          id="canvas-height"
          value={canvasHeight}
          onChange={(e) => {
            if (Number(e.target.value) <= 0) return;
            setCanvasHeight(Number(e.target.value));
          }}
        />
      </label>
    </div>
  );
};

export default ToolBar;
