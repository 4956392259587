import { combineReducers } from 'redux';

import * as types from '../actions/actionTypes';
import { DRAW_STYLE } from '../lib/constants';

const brushColor = (state = '#000000', action) => {
  switch (action.type) {
    case types.SET_BRUSH_COLOR:
      return action.brushColor;
    default:
      return state;
  }
};

const brushSize = (state = 4, action) => {
  switch (action.type) {
    case types.SET_BRUSH_SIZE:
      return action.brushSize;
    default:
      return state;
  }
};

const drawStyle = (state = DRAW_STYLE.FREEFORM, action) => {
  switch (action.type) {
    case types.SET_DRAW_STYLE:
      return action.drawStyle;
    default:
      return state;
  }
};

const showDrawingToolbar = (state = true, action) => {
  switch (action.type) {
    case types.SET_SHOW_DRAWING_TOOLBAR:
      return action.showDrawingToolbar;
    default:
      return state;
  }
};

const showSelectedObjectTools = (state = false, action) => {
  switch (action.type) {
    case types.SET_SHOW_SELECT_OBJECT_TOOLBAR:
      return action.showSelectedObjectTools;
    default:
      return state;
  }
};

export default combineReducers({
  brushColor,
  brushSize,
  drawStyle,
  showDrawingToolbar,
  showSelectedObjectTools,
});
